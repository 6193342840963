import {create} from 'zustand';

export interface WorkingHours {
  monday: {
    start: string;
    end: string;
  };
  tuesday: {
    start: string;
    end: string;
  };
  wednesday: {
    start: string;
    end: string;
  };
  thursday: {
    start: string;
    end: string;
  };
  friday: {
    start: string;
    end: string;
  };
  saturday: {
    start: string;
    end: string;
  };
  sunday: {
    start: string;
    end: string;
  };
}
export interface UserPreferences {
  theme: 'light' | 'dark';
  notificationPreferences: {
    email: boolean;
    push: boolean;
  };
  workingHours: WorkingHours;
  minSegmentDuration: number;
}
export  interface User {
  _id: string;
  name: string;
  email: string;
  preferences: UserPreferences;
  createdAt: Date;
  updatedAt: Date;
}

interface UsersStore {
  user: User | null;
  error: string | null;
  userPreferencesUpdated: boolean;
  setUser: (user: User) => void;
  setError: (error: string) => void;
  setUserPreferencesUpdated: () => void;
}

const useUsersStore = create<UsersStore>((set) => ({
  user: null,
  error: null,
  userPreferencesUpdated: false,
  setUser: (user: User) => set({user}),
  setError: (error: string) => set({error}),
  setUserPreferencesUpdated: () => set((state) => ({...state, userPreferencesUpdated: !state.userPreferencesUpdated}))

}));

export {useUsersStore};
import { WorkingHours } from "@/pages/App/store/users";
import { useEffect, useRef } from "react"
import { getDateINReadableFormat, getDaysFromStartToEndOfSchedule, getEventsOfTheDay, getFormatedSchedulePeriod, getListOfHoursFromPeriod, getMaxOfWorkingHours, getTasksOfTheDay } from "./calendar-utils";
import useSchedulesStore, { Schedule, SortedTask } from "@/store/schedules";
import schedulesService from "@/services/schedules";
import { ChevronLeftIcon, ChevronRightIcon } from "@radix-ui/react-icons";
import DayCalendar from "./components/DayCalendar";
import { Algorithm } from "@/store/schedules";
import useCalendarStore from "@/store/calendar";

const scheduleLimit = 10;

const defaultWorkingHours: WorkingHours = {
  "monday": {
    start: "09:00:00",
    end: "17:00:00",
  },
  "tuesday": {
    start: "09:00:00",
    end: "17:00:00",
  },
  "wednesday": {
    start: "09:00:00",
    end: "17:00:00",
  },
  "thursday": {
    start: "09:00:00",
    end: "17:00:00",
  },
  "friday": {
    start: "09:00:00",
    end: "17:00:00", 
  },
  "saturday": {
    start: "09:00:00",
    end: "17:00:00",
  },
  "sunday": {
    start: "09:00:00",
    end: "17:00:00",
  },
}



export function Calendar(props: {workingHours?: WorkingHours}) {

  const { workingHours } = props;
  const { pageChanged, setPageChanged, schedules, setSchedules, shownSchedule, setShownSchedule, shownSchedulePage, setShownSchedulePage, shownScheduleIndex, setShownScheduleIndex, shownSchedulePageCount, setShownSchedulePageCount, sortedTasks, setSortedTasks, days, setDays } = useCalendarStore();
  const todayRef = useRef<HTMLDivElement | null>(null);
  const overflowContainerRef = useRef<HTMLDivElement | null>(null);
  const {focusSaved} = useSchedulesStore();

  useEffect(() => {
    
    if (overflowContainerRef.current && todayRef.current) {
      const container = overflowContainerRef.current;
      const todayElement = todayRef.current;
  
  
      const scrollLeft =
        todayElement.offsetLeft - container.offsetLeft - container.clientWidth / 2 + todayElement.clientWidth / 2;
  
      // Ensure the scroll position is within bounds
      const maxScrollLeft = container.scrollWidth - container.clientWidth;
      const adjustedScrollLeft = Math.min(Math.max(scrollLeft, 0), maxScrollLeft);
  
      // Set the scroll position
      container.scrollTo({
        left: adjustedScrollLeft,
        behavior: 'smooth'
      });
    }
  }, [days, focusSaved]);

  useEffect(() => {
    if(!shownSchedule){
      schedulesService.getSchedules('future', 0, scheduleLimit).then((sched) => {
        if(sched.data.length > 0) {
          setShownSchedulePage(0);
          setShownScheduleIndex(0);
          setShownSchedulePageCount(Math.ceil(sched.total / sched.data.length));
          setShownSchedule(sched.data[0]);
          setSchedules(sched.data);
        }
      setDays(getDaysFromStartToEndOfSchedule(sched.data[0]));

      if(sched.data[0]?.algorithm){
        schedulesService.getSortedTasks(sched.data[0]).then((tasks: {algorithm: Algorithm, sortedTasks: SortedTask[]}[]) => {
          const task = tasks.find((task: {algorithm: Algorithm, sortedTasks: SortedTask[]}) => task.algorithm._id === sched.data[0]?.algorithm?._id);
          if(task){
            setSortedTasks(task.sortedTasks);
          }
        })
      } 

      })
    }
  }, [])

  useEffect(() => {
    schedulesService.getSchedules('future', 0, scheduleLimit).then((sched) => {
      if(sched.data.length > 0) {
        setShownSchedulePage(0);
        setShownScheduleIndex(0);
        setShownSchedulePageCount(Math.ceil(sched.total / sched.data.length));
        setShownSchedule(sched.data[0]);
        setSchedules(sched.data);
      }
    setDays(getDaysFromStartToEndOfSchedule(sched.data[0]));

    if(sched.data[0]?.algorithm){
      schedulesService.getSortedTasks(sched.data[0]).then((tasks: {algorithm: Algorithm, sortedTasks: SortedTask[]}[]) => {
        const task = tasks.find((task: {algorithm: Algorithm, sortedTasks: SortedTask[]}) => task.algorithm._id === sched.data[0]?.algorithm?._id);
        if(task){
          setSortedTasks(task.sortedTasks);
        }
      })
    } 

    })
  }, [focusSaved]);

  const time = getMaxOfWorkingHours(workingHours ?? defaultWorkingHours);
  const hours = getListOfHoursFromPeriod(time);

  const handleNextSchedule = () => {
    let isPageChanged = false;
    let currentSchedule = shownSchedule;
    if(shownSchedulePage < 0) {
      if(shownScheduleIndex === 0) {
        setShownSchedulePage(shownSchedulePage + 1);
        setShownScheduleIndex(0);
        isPageChanged = true;
      } else {
        setShownScheduleIndex(shownScheduleIndex - 1);
        setShownSchedule(schedules[shownScheduleIndex - 1]);
        currentSchedule = schedules[shownScheduleIndex - 1];
      }
    } else {
      if(shownScheduleIndex === schedules.length - 1) {
        if(shownSchedulePage >= shownSchedulePageCount - 1) return;
        setShownSchedulePage(shownSchedulePage + 1);
        setShownScheduleIndex(0);
        isPageChanged = true;
      } else {
        setShownScheduleIndex(shownScheduleIndex + 1);
        setShownSchedule(schedules[shownScheduleIndex + 1]);
        currentSchedule = schedules[shownScheduleIndex + 1];
      }
    }

    if(!isPageChanged) {
      setDays(getDaysFromStartToEndOfSchedule(currentSchedule as Schedule));
      setSortedTasks([]);
      if(currentSchedule?.algorithm){
        schedulesService.getSortedTasks(currentSchedule as Schedule).then((tasks: {algorithm: Algorithm, sortedTasks: SortedTask[]}[]) => {
          const task = tasks.find((task: {algorithm: Algorithm, sortedTasks: SortedTask[]}) => task.algorithm._id === currentSchedule?.algorithm?._id);
          if(task){
            setSortedTasks(task.sortedTasks);
          }
        })
      } 
    } else {
      setPageChanged(true);
    }
  }

  const handlePreviousSchedule = () => {
    let isPageChanged = false;
    let currentSchedule = shownSchedule;
    if(shownSchedulePage >= 0) {
      if(shownScheduleIndex === 0) {
        setShownSchedulePage(shownSchedulePage - 1);
        setShownScheduleIndex(0);
        isPageChanged = true;
      } else {
        setShownScheduleIndex(shownScheduleIndex - 1);
        setShownSchedule(schedules[shownScheduleIndex - 1]);
        currentSchedule = schedules[shownScheduleIndex - 1];
      }
    } else {
      if(shownScheduleIndex === schedules.length - 1) {
        if((shownSchedulePage)*(-1) >= shownSchedulePageCount) return;
        setShownSchedulePage(shownSchedulePage - 1);
        setShownScheduleIndex(0);
        isPageChanged = true;
      } else {
        setShownScheduleIndex(shownScheduleIndex + 1);
        setShownSchedule(schedules[shownScheduleIndex + 1]);
        currentSchedule = schedules[shownScheduleIndex + 1];
      }
    }

    if(!isPageChanged) {
      setDays(getDaysFromStartToEndOfSchedule(currentSchedule as Schedule));
      if(currentSchedule?.algorithm){
        schedulesService.getSortedTasks(currentSchedule as Schedule).then((tasks: {algorithm: Algorithm, sortedTasks: SortedTask[]}[]) => {
          const task = tasks.find((task: {algorithm: Algorithm, sortedTasks: SortedTask[]}) => task.algorithm._id === currentSchedule?.algorithm?._id);
          if(task){
            setSortedTasks(task.sortedTasks);
          }
        })
      } 
    } else {
      setPageChanged(true);
    }
  }


  useEffect(() => {

    if(!pageChanged) return;
    setSortedTasks([]);
    if(shownSchedulePage >= 0) {
      schedulesService.getSchedules('future', shownSchedulePage, scheduleLimit).then((sched) => {
        setSchedules(sched.data);
        setShownSchedule(sched.data[0]);
        setShownScheduleIndex(0);
        setShownSchedulePageCount(Math.ceil(sched.total / sched.data.length));
        setDays(getDaysFromStartToEndOfSchedule(sched.data[0]));
        if(sched.data[0]?.algorithm){
          schedulesService.getSortedTasks(sched.data[0]).then((tasks: {algorithm: Algorithm, sortedTasks: SortedTask[]}[]) => {
            const task = tasks.find((task: {algorithm: Algorithm, sortedTasks: SortedTask[]}) => task.algorithm._id === sched.data[0]?.algorithm?._id);
            if(task){
              setSortedTasks(task.sortedTasks);
            }
          })
        } 
      })
    } else {
      schedulesService.getSchedules('past', (shownSchedulePage+1)*(-1), scheduleLimit, 'desc').then((sched) => {
        setSchedules(sched.data);
        setShownSchedule(sched.data[0]);
        setShownScheduleIndex(0);
        setShownSchedulePageCount(Math.ceil(sched.total / sched.data.length));
        setDays(getDaysFromStartToEndOfSchedule(sched.data[0]));
        if(sched.data[0]?.algorithm){
          schedulesService.getSortedTasks(sched.data[0]).then((tasks: {algorithm: Algorithm, sortedTasks: SortedTask[]}[]) => {
            const task = tasks.find((task: {algorithm: Algorithm, sortedTasks: SortedTask[]}) => task.algorithm._id === sched.data[0]?.algorithm?._id);
            if(task){
              setSortedTasks(task.sortedTasks);
            } 
          })
        } 
      })
    }

    setPageChanged(false);


  }, [shownSchedulePage])





  return (
    <div className="">
      <div className="flex justify-between items-start">
        <div className="text-3xl font-bold mb-14">{getDateINReadableFormat(new Date())}</div>
        <div className="flex items-center gap-3">
          <ChevronLeftIcon className="w-10 h-10 cursor-pointer" onClick={handlePreviousSchedule} />
          <div className="flex flex-col text-center">
            <div className="text-md font-light ">{shownSchedule?.name}</div>
            <div className="text-sm font-thin ">{getFormatedSchedulePeriod(shownSchedule)}</div>
          </div>
          <ChevronRightIcon className="w-10 h-10 cursor-pointer" onClick={handleNextSchedule} />
        </div>

      </div>
      <div className="flex flex-col w-full justify-center bg-white p-5">
        <div className="flex items-start justify-items-stretch gap-2 w-full">
          <div className="w-20 text-center">
            <div className="font-bold my-5 text-sm">August</div>
            {hours.map((hour) => (
              <div key={hour} className="font-light text-sm h-[80px]">{hour.toString().padStart(2, '0')}h00</div>
            ))}
          </div>
          <div className="flex gap-2 overflow-x-auto" ref={overflowContainerRef}>
          {shownSchedule && days.map((day) => (
            <DayCalendar ref={day.current ? todayRef : null} schedule={shownSchedule} day={day} events={getEventsOfTheDay(day, shownSchedule?.events) ?? []} tasks={getTasksOfTheDay(day, sortedTasks) ?? []} hours={hours} />
          ))}
          </div>
        </div>
      </div>
    </div>
    
  )
}

import { useState } from 'react'
import { Dialog, DialogDescription, DialogTitle, DialogContent } from '../ui/dialog'
import { useAuthStore } from '@/store/auth';
import { Button } from '../ui/button';

import confetti from 'canvas-confetti';

function OnboardingVideo(props: { open: boolean}) {

  const [open, setOpen] = useState(props.open);
  const {setOnboardingCompleted} = useAuthStore();
  


  const runConfetti = () => {
    const duration = 5 * 1000;
    const animationEnd = Date.now() + duration;
    const defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };
 
    const randomInRange = (min: number, max: number) =>
      Math.random() * (max - min) + min;
 
    const interval = window.setInterval(() => {
      const timeLeft = animationEnd - Date.now();
 
      if (timeLeft <= 0) {
        return clearInterval(interval);
      }
 
      const particleCount = 50 * (timeLeft / duration);
      confetti({
        ...defaults,
        particleCount,
        origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
      });
      confetti({
        ...defaults,
        particleCount,
        origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
      });
    }, 250);
  };

  const setAsDone = async () => {
    if(open){
      await setOnboardingCompleted();
      setOpen(false);
    }
  };

  

  return (
    <div>
      <Dialog open={open} onOpenChange={setOpen} >
        <DialogContent className='min-h-[80vh] min-w-[80vw]'  onLoad={runConfetti}>


          <DialogTitle>
            Thank you for signing up!
          </DialogTitle>
          <DialogDescription className='text-center'>
            Here a quick video to help you get started 🎯
          </DialogDescription>

          <iframe className="min-h-[50vh] min-w-[50vw] m-auto border-none" src="https://www.youtube.com/embed/x0SQaKYAe8s?si=G8XXBfeTb6SnvCD0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
          <Button onClick={setAsDone} variant='destructive' className='m-auto mt-5'>I'm done</Button>
        </DialogContent>

      </Dialog>

    </div>
  )
}

export default OnboardingVideo
import ShinyButton from '@/components/magicui/shiny-button'
import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import { Input } from '@/components/ui/input';
import { cn } from '@/lib/utils';
import { Cross2Icon, MagicWandIcon, PlusIcon } from '@radix-ui/react-icons';
import React from 'react'
import schedulesService from '@/services/schedules';

type Subtask = {
  title: string;
  done: boolean;
}

function SubTasksInput(props: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: any;
  value: Subtask[];
  onChange: (value: Subtask[]) => void;
  task: { title: string, notes: string };
}) {
  const { value, onChange } = props;

  const [subtask, setSubtask] = React.useState('');

  const handleGenerateSubtasks = async () => {
    const subtasks = await schedulesService.generateSubtasks(props.task.title, props.task.notes).catch((error) => {
      console.log(error)
      props.form.setError('subtasks', {
        type: 'manual',
        message: error.response.data.errors
      });
    })
    if(subtasks) onChange(subtasks.map(subtask => ({ title: subtask, done: false })));
  };

  const handleAddSubtask = () => {
    if (subtask.trim() === '') return;

    const newSubtask: Subtask = {
      title: subtask,
      done: false
    };

    onChange([...value, newSubtask]);
    setSubtask('');
  };

  const handleDeleteSubtask = (index: number) => {
    const newSubtasks = value.filter((_, i) => i !== index);
    onChange(newSubtasks);
  }

  const handleChangeSubtask = (done: boolean, index: number) => {
    const newSubtasks = value.map((subtask, i) => {
      if (i === index) {
        return {
          ...subtask,
          done
        }
      }
      return subtask;
    });

    onChange(newSubtasks);
  }

  return (
    <div className='mb-3'>
      <div className="font-md font-light">
        Subtasks
        <ShinyButton onClick={()=>handleGenerateSubtasks()} className='ml-3 py-2 px-2 bg-white/20 text-white  disabled:border-0' type='button'><MagicWandIcon/></ShinyButton>

      </div>

      <div className="flex flex-row items-center gap-2">

        <Input placeholder="Add a subtask" value={subtask} onChange={(e) => setSubtask(e.target.value)} className='bg-transparent ring-0 font-thin border-0 outline-0 focus-visible:outline-0 focus-visible:border-0 focus-visible:ring-0 focus-visible:ring-offset-0'/>
        <Button className='bg-white/20' size='sm' variant={'secondary'} onClick={handleAddSubtask} type='button' ><PlusIcon/></Button>
      </div>
      <div className='h-24 overflow-y-auto'>
          {
            value.map((subtask, index) => (
              <div className="flex items-center justify-between gap-2 mb-2" key={index}>
           
                <div className='flex items-center gap-2' >
                  <Checkbox defaultChecked={subtask.done} onCheckedChange={(checked: boolean) => handleChangeSubtask(checked, index)} />
                  <div className={cn("text-sm font-light", subtask.done ? "line-through text-black/30" : null)}>{subtask.title}</div>
                </div>
                <Cross2Icon className='cursor-pointer mr-3' onClick={()=>handleDeleteSubtask(index)} />
              </div>
             
            ))
          }
      </div>
    </div>
  )
}

export default SubTasksInput
import { Button } from '@/components/ui/button'
import { PlusIcon } from '@radix-ui/react-icons'

function AddScheduleButton() {
  return (
    <Button className="bg-gray-800" size="sm">
      <PlusIcon className="mr-2 h-4 w-4"/> <span className="text-sm">New Schedule</span>
    </Button>
  )
}

export default AddScheduleButton

import { z } from 'zod';
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import Logo from '@/assets/logo.svg'
import { TypographyP } from '@/components/typography/TypographyP';
import { Link, useNavigate } from 'react-router-dom';
import { useAuthStore } from '@/store/auth';
import { useEffect, useState } from 'react';
import { ReloadIcon } from '@radix-ui/react-icons';
import Footer from '../App/components/Footer/Footer';
import { PasswordInput } from '@/components/ui/password-input';

const formSchema = z.object({
  email: z.string().email(),
  password: z.string().min(6),
});


function LoginForm() {


  const { login, error, isAuthenticated } = useAuthStore();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });


  useEffect(() => {
    

    if(isAuthenticated) {
      navigate('/app');
    }
    
  });

  function onSubmit(values: z.infer<typeof formSchema>) {
    const { email, password } = values
    setIsLoading(true);
    login(email, password).then(() => {
      setIsLoading(false);
      console.log('logged in');
      console.log(isAuthenticated);
    });

  }

  return (
    <div className='min-h-screen min-w-fit flex flex-col justify-between'>
      <div className="flex flex-col justify-evenly  py-10">
        <div className="flex flex-col items-center ">

          <div className="flex mb-20 ">
            <img 
              src={Logo} 
              alt="logo" 
              width={300}
            />
          </div>
          
          <div className="flex ">
            <Form {...form}>
              <form className='' onSubmit={form.handleSubmit(onSubmit)}>
                <div className="flex flex-col gap-4 items-center">
                  <div className="flex">
                    <FormField
                      control={form.control}
                      name='email'
                      render={() => (
                        <FormItem className='w-96'>
                          <FormLabel>Email</FormLabel>
                          <FormControl>
                            <Input
                              {...form.register("email")}
                              placeholder="Email"
                              type="email"
                              
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                        
                      )}
                    />
                  </div>

                  <div className="flex">
                    <FormField
                      control={form.control}
                      name='password'
                      render={(field) => (
                        <FormItem className='w-96'>
                          <FormLabel>Password</FormLabel>
                          <FormControl>
                            <PasswordInput
                              value={field.field.value}
                              onChange={field.field.onChange}
                              placeholder="Password"
                              
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                        
                      )}
                    />
                  </div>
                  <div className="flex">
                    <Button className={`flex rounded-none h-10 w-40 ${isLoading ? 'disabled' : ''}`} type="submit">
                      {isLoading ? <ReloadIcon className="mr-2 h-4 w-4 animate-spin" /> : null}
                      Login
                    </Button>
                  </div>
                  <div className="flex">
                    {error && <div className="text-red-500">{error}</div>}
                  </div>
                </div>

              </form>
            </Form>
          </div>
        </div>
        
        <div className="text-center ">
          <TypographyP>
            Don't have an account? <Link to='/register' className='underline'>Register</Link>
          </TypographyP>
        </div>
      </div>
      <Footer />
    </div>
   
  );
}


function LoginPage() {
  return (
    <div>
      <LoginForm />
    </div>
  )
}

export default LoginPage

import { useEffect, useState } from "react";
import NavBar from "../components/NavBar/NavBar"
import AddScheduleButton from "./components/AddScheduleButton"
import SelectTimelineDirection from "./components/SelectTimelineDirection"
import useSchedulesStore, { Schedule } from "../../../store/schedules";
import ScheduleCard from "./components/ScheduleCard/ScheduleCard";
import { getMonthNameInUsersLocale } from "@/utils/utils";
import Footer from "../components/Footer/Footer";
import ScheduleDialog from "./components/ScheduleDialog/ScheduleDialog";
import schedulesService from "../../../services/schedules";
import { Button } from "@/components/ui/button";
import OnboardingVideo from "@/components/onboarding/OnboardingVideo";
import { useAuthStore } from "@/store/auth";

const limit = 10;

function SchedulesPage() {
  

  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const { auth } = useAuthStore();

  const { schedules, scheduleCreated, scheduleDeleted, taskCreated, taskDeleted, setSchedules, focusSaved, scheduleModified, selectedTimelineDirection, setSelectTimelineDirection } = useSchedulesStore()
  let renderingMonth = "";

  useEffect((): void => {
      schedulesService.getSchedules(selectedTimelineDirection, currentPage, limit).then(
        (schedules) => {
          setSchedules(schedules.data.map((schedule: Schedule) => {
            console.log('schedule', schedule);
            return new Schedule(schedule);
          }));
          setTotalPages(schedules.total/limit);
        }
      );

      
    
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    scheduleCreated,
    scheduleDeleted,
    taskCreated,
    taskDeleted,
    selectedTimelineDirection,
    focusSaved,
    scheduleModified,
    setSchedules,
  ]);

  const handleLoadMore = () => {
    setCurrentPage(currentPage + 1);
    schedulesService.getSchedules(selectedTimelineDirection, currentPage + 1, limit).then(
      (newSchedules) => {
        setSchedules([...schedules, ...newSchedules.data.map((newSchedule: Schedule) => {
          return new Schedule(newSchedule);
        })]);
      }
    );
  }


  return (
    <div className="flex flex-col pt-5 px-16  min-h-screen">
      <NavBar page="schedules" className="pb-20" />
      <div className="flex flex-row justify-between">
        <SelectTimelineDirection onChange={setSelectTimelineDirection} selected={selectedTimelineDirection} />
        <ScheduleDialog type="add" >
          <AddScheduleButton />
        </ScheduleDialog>
      </div>
      <div className="pb-10">
        { 
          schedules.length ?
          schedules.map((schedule) => {
            const isSameMonth = renderingMonth === getMonthNameInUsersLocale(schedule.period.start);
            renderingMonth = (getMonthNameInUsersLocale(schedule.period.start));
            return (
              <ScheduleCard 
                schedule={schedule} 
                key={schedule._id} 
                isFirstOfTheMonth={!isSameMonth}
              />
            )
          }) :
          <div className="m-20">
            <p className="text-xl text-gray-500 text-center">No schedules found</p>
          </div>
        }
      </div>
      {
        currentPage < totalPages -1 && (
          <div className="flex flex-row justify-center">
            <Button className="font-bold py-1 px-4 rounded border-dashed" variant="outline" onClick={handleLoadMore}>
              Load More
            </Button>
          </div>
        )
      }
      <OnboardingVideo open={!auth.user?.onboardingCompleted} />

      <Footer />
      
    </div>
  )
}

export default SchedulesPage
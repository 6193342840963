
import axios from 'axios';
import {jwtDecode} from 'jwt-decode'

import { useAuthStore } from '@/store/auth';
const  URL = import.meta.env.VITE_API_URL;
if(!URL) {
  throw new Error('VITE_API_URL is not defined');
}
const axiosInstance = axios.create({
  // You can add custom configuration here
  baseURL: URL,
  timeout: 2000,
});



const initAuthInAxios = () => { 

  axiosInstance.interceptors.request.use(async (config) => {
    const auth = useAuthStore.getState().auth;
    const { token, refreshToken } = auth;
    const decodedToken = jwtDecode(token as string);
    const expiresIn = (decodedToken.exp as number) - Date.now() / 1000;

    if(expiresIn < 60) {
      try {
        auth.token = (await axios.post(`${URL}/auth/refresh`, {}, {
          headers: {
            'X-REFRESH-TOKEN': refreshToken
          }
        })).data.token;
        localStorage.setItem('auth', JSON.stringify(auth));
        useAuthStore.getState().auth = auth;
      } catch (error) {
        console.log(error);
        const { logout } = useAuthStore.getState();
        logout();
      }
    }
    config.headers.Authorization = `Bearer ${auth.token}`;
  
    return config;
  },
  (error) => {
    return Promise.reject(error);
  });
}

export default axiosInstance;
export { initAuthInAxios };
import { useAuthStore } from '@/store/auth';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

const ProtectedRoute = () => {
  const {isAuthenticated, auth} = useAuthStore();
  const location = useLocation();
  if(!auth.user?.emailConfirmed) {
    return <Navigate to="/email-confirmation" state={{ from: location }} replace />;
  }
  if (!isAuthenticated) {
    // Redirect to login page with the current location saved
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
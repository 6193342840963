import React from 'react'
import WorkingHoursPicker from '../../Schedules/components/ScheduleDialog/components/WorkingHoursPicker'
import { User } from '../../store/users';
import { WorkingHours } from '../../store/users';

type DayWorkingHours = {
  start: string;
  end: string;
}



function WeekWorkingHoursPicker(props: { user: User, onChange: (workingHours: WorkingHours) => void }) {

  const { user } = props;

  const mondayDateState = React.useState<DayWorkingHours>(user?.preferences.workingHours.monday || {start: '00:00:00', end: '00:00:00'});
  const tuesdayDateState = React.useState<DayWorkingHours>(user?.preferences.workingHours.tuesday || {start: '00:00:00', end: '00:00:00'});
  const wednesdayDateState = React.useState<DayWorkingHours>(user?.preferences.workingHours.wednesday || {start: '00:00:00', end: '00:00:00'});
  const thursdayDateState = React.useState<DayWorkingHours>(user?.preferences.workingHours.thursday || {start: '00:00:00', end: '00:00:00'});
  const fridayDateState = React.useState<DayWorkingHours>(user?.preferences.workingHours.friday || {start: '00:00:00', end: '00:00:00'});
  const saturdayDateState = React.useState<DayWorkingHours>(user?.preferences.workingHours.saturday || {start: '00:00:00', end: '00:00:00'});
  const sundayDateState = React.useState<DayWorkingHours>(user?.preferences.workingHours.sunday || {start: '00:00:00', end: '00:00:00'});

  const dateStates = [ 
    mondayDateState, 
    tuesdayDateState, 
    wednesdayDateState, 
    thursdayDateState, 
    fridayDateState, 
    saturdayDateState, 
    sundayDateState 
  ];



  const handleChange = (index: number) => {
    return (workingHours: DayWorkingHours) => {
      dateStates[index][1](workingHours);
      const newWeekWorkingHours = {
        monday: mondayDateState[0],
        tuesday: tuesdayDateState[0],
        wednesday: wednesdayDateState[0],
        thursday: thursdayDateState[0],
        friday: fridayDateState[0],
        saturday: saturdayDateState[0],
        sunday: sundayDateState[0],
      }

      const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'] as const;
      const day: typeof days[number] = days[index];
      newWeekWorkingHours[day] = workingHours;

      props.onChange(newWeekWorkingHours);
    }
  }

  return (
    <div>
      {
        ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day, index: number) => (
          <WorkingHoursPicker 
            key={day} 
            day={day} 
            workingHours={dateStates[index][0]}
            setWorkingHours={handleChange(index)}
          />
        )) 
      }
    </div>
  )
}

export default WeekWorkingHoursPicker

import SparklesText from '@/components/magicui/sparkles-text'
import { Fade } from 'react-awesome-reveal'
import logoWithoutSlogan from '@/assets/logo-sloganless.svg'
import { Button } from '@/components/ui/button'
import { useNavigate } from 'react-router-dom'
import { RotatingText } from '@/components/ui/rotating-text'
import screenrecoding from '@/assets/screenrecording.mp4'
import ShineBorder from '@/components/magicui/shine-border'
import { AutoPlayVideoPlayer } from '@/components/ui/auto-play-video-player'
import { AlarmClockCheck, BellDot, BrainCircuit, CalendarCog, MonitorSmartphone, Target } from 'lucide-react'
import Footer from '@/pages/App/components/Footer/Footer';

const features = [
  {
    icon: <CalendarCog  color='white' className='rounded-full bg-black p-3 w-12 h-auto' />,
    title: 'Task Management Made Easy:',
    description: 'Keep track of everything you need to do with our simple, two-layer task organization system. Add, edit, and manage tasks and subtasks effortlessly.'
  },
  {
    icon: <Target  color='white' className='rounded-full bg-black p-3 w-12 h-auto' />,
    title: 'Intelligent Scheduling:',
    description: 'Tamskirt uses advanced algorithms to structure your week, balancing deadlines, priorities, and work time. Select your Focus—whether it’s deadlines, urgent tasks, or a balanced workload—for a schedule tailored to your needs.'
  },
  {
    icon: <MonitorSmartphone  color='white' className='rounded-full bg-black p-3 w-12 h-auto' />,
    title: 'Sync Across Devices:',
    description: 'Export your schedules to any calendar app using the ICS format to keep tasks synced across all your devices. You can also use the app’s built-in calendar. Auto-sync is coming soon!'
  },
  {
    icon: <AlarmClockCheck  color='white' className='rounded-full bg-black p-3 w-12 h-auto' />,
    title: 'Tailored Scheduling:',
    description: 'Customize your schedule to fit your unique needs. Whether you prefer certain days and hours for tasks or need to make quick adjustments, the flexibility is just a few clicks away.'
  },
  {
    icon: <BrainCircuit color='white' className='rounded-full bg-black p-3 w-12 h-auto' />,
    title: 'AI-Powered Scheduling:',
    description: 'Our app uses AI to suggest subtasks and estimate task duration. Coming soon: PlanPal, an AI assistant that will help you craft custom scheduling preferences using natural language.'
  },
  {
    icon: <BellDot color='white' className='rounded-full bg-black p-3 w-12 h-auto' />,
    title: 'Reminders & Notifications (Coming Soon):',
    description: 'Get timely reminders so you never miss a task. Set custom notifications for recurring or important tasks to stay on track effortlessly.'
  },
  

]

function HomePage() {

  const navigate = useNavigate();
  return (
    <div className='min-h-[100vh] min-w-[100vw]  p-10'>
      <Fade delay={1100}>
        <img src={logoWithoutSlogan} alt="logo" className="w-[150px] h-auto"/>
      </Fade>
      <div className='flex flex-col items-center justify-center mt-36 '>

          <div>

            <Fade delay={500} className='text-6xl font-extrabold text-center grid '>
              <RotatingText />
            </Fade>
            <Fade delay={600}><SparklesText text=" Minutes" className='text-6xl text-center font-bold'/></Fade>
            <Fade delay={900}>
              <p className='text-xl font-light mt-5 text-center'>Effortlessly organize your tasks, prioritize what matters, and stay ahead of your schedule with Tamskirt.</p>
            </Fade>

            <Fade delay={1200}>
            <div className='flex items-center justify-center gap-5 mt-5'>
              <Button onClick={() => navigate('/register')}>Get Started</Button>
              <Button variant='secondary' onClick={() => navigate('/login')}>Login</Button>
            </div>
          </Fade>
        
          </div>
          {/* <Fade >
            <img src={illustration} alt="illustration" className="w-[500px] h-auto"/>
          </Fade> */}

          <Fade className="mt-20" delay={1300}>
            <ShineBorder className='p-0 w-[70vw]' >
              <AutoPlayVideoPlayer src={screenrecoding} />
            </ShineBorder>
          </Fade>


          <Fade delay={200}>
            <div className="my-20">
              <div className="text-4xl text-center mb-10 font-thin">Features</div>
              <div className="grid grid-cols-3 w-[80vw] items-start text-center gap-10 ">
                {features.map((feature, index) => (
                  <div key={index} className='p-2 flex flex-col items-center'>
                    {feature.icon}
                    <p className='text-lg font-light my-2'>{feature.title}</p>
                    <p className='text-sm font-thin'>{feature.description}</p>
                  </div>
                ))}
              </div>
            </div>
            
          </Fade>

          

      </div>
      <Footer />
    </div>

  )
}

export default HomePage
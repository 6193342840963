
import {create} from 'zustand';
import authService from '@/services/authService';
import { AxiosError } from 'axios';
import { initAuthInAxios } from '@/utils/axios';

type AuthData = {
  user: {
    name: string;
    email: string;
    emailConfirmed: boolean;
    onboardingCompleted: boolean;
  } | null;

  token: string | null;
  refreshToken: string | null;
};

type AuthStore = {
  auth: AuthData;
  error: string | null;
  isAuthenticated: boolean;
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
  init: () => void;
  confirmEmail: (code: string) => Promise<void>;
  setOnboardingCompleted: () => void;
};

export const useAuthStore = create<AuthStore>((set) => ({
  auth: {
    user: null,
    token: null,
    refreshToken: null,
  },
  error: null,
  isAuthenticated: false,
  login: async (email: string, password: string) => {
    try {
      const auth = await authService.login(email, password);
      localStorage.setItem('auth', JSON.stringify(auth));
      set({ auth, isAuthenticated: true });
      initAuthInAxios();
    }
    catch (error) {
      if(error instanceof AxiosError) {
        set({ error: error.response?.data });
      }
    }
  },
  logout: () => {
    localStorage.removeItem('auth');
    set({ auth: { user: null, token: null, refreshToken: null }, isAuthenticated: false });
  },
  init: () => {
    const auth = JSON.parse(localStorage.getItem('auth') as string);
    if(auth) {
      authService.initAuth(auth);
      set({ auth, isAuthenticated: true });
      
    } else {
      set({ auth: { user: null, token: null, refreshToken: null }, isAuthenticated: false });
    }
  },
  confirmEmail: async (code: string) => {
    try {
      const respo = await authService.confirmEmail(code);
      console.log(respo);
      if(respo === 'Email confirmed') {
        localStorage.removeItem('auth');
        set({ auth: { user: null, token: null, refreshToken: null }, isAuthenticated: false });
      }
        
    } catch (error) {
      if(error instanceof AxiosError) {
        set({ error: error.response?.data });
      }
    }
  },
  setOnboardingCompleted: async() => {
    try {
      await authService.setOnboardingCompleted();
      set((state) => {
        if(state.auth.user) {
          state.auth.user.onboardingCompleted = true;
        }
        return state;
      });
      const localAuth = JSON.parse(localStorage.getItem('auth') as string);
      if(!localAuth) throw new Error('No auth data found in local storage');
      localStorage.setItem('auth', JSON.stringify({ ...localAuth, user: { ...localAuth.user, onboardingCompleted: true } }));
      console.log(JSON.stringify({ ...localAuth, user: { ...localAuth.user, onboardingCompleted: true } }));
      console.log('Onboarding completed');
    } catch (error) {
      if(error instanceof AxiosError) {
        set({ error: error.response?.data });
      }
    }
  }
}));
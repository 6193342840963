
export function getReadableDate(date: Date): string {
  return date.toISOString().replace(/\..*/, '').replace('T', ' ')
}

export function getReadableDateInUsersLocale(date: Date): string {
  return date.toLocaleString()
}

export function getMonthNameInUsersLocale(date: Date): string { 
  return date.toLocaleString('default', { month: 'long' })
}

export function convertTimeToSeconds(time: string): number {
  const timeValue = parseInt(time);
  const timeUnit = time.slice(-1);

  switch (timeUnit) {
    case 's':
      return timeValue;
    case 'm':
      return timeValue * 60;
    case 'h':
      return timeValue * 60 * 60;
    case 'd':
      return timeValue * 60 * 60 * 24;
    default:
      return timeValue;
  }
}

export function convertSecondsToTime(seconds: number | undefined): string {
  if(seconds === undefined) return '';
  if (seconds < 60) {
    return Math.ceil(seconds) + 's';
    } else if (seconds < 3600) {
    return Math.ceil(seconds / 60) + 'm';
    } else if (seconds < 86400) {
    return Math.ceil(seconds / 3600) + 'h';
    } else {
    return Math.ceil(seconds / 86400) + 'd';
    }
}

export function setWorkingHoursToUtc(workingHours: { start: string, end: string }): { start: string, end: string } {
  // example of working hours in local time: { start: '09:00:00', end: '17:00:00' }
  // example of working hours in utc time: { start: '07:00:00', end: '15:00:00' }
  const start = workingHours.start.split(':');
  const end = workingHours.end.split(':');
  const startHour = parseInt(start[0]);
  const endHour = parseInt(end[0]);
  const startMinute = parseInt(start[1]);
  const endMinute = parseInt(end[1]);
  const startSecond = parseInt(start[2]);
  const endSecond = parseInt(end[2]);
  const startUtc = new Date();
  const endUtc = new Date();
  startUtc.setHours(startHour, startMinute, startSecond);
  endUtc.setHours(endHour, endMinute, endSecond);
  //add 0 if hour minute or second is less than 10
  const newStart = `${startUtc.getUTCHours().toString().padStart(2, '0')}:${startUtc.getUTCMinutes().toString().padStart(2, '0')}:${startUtc.getUTCSeconds().toString().padStart(2, '0')}`;
  const newEnd = `${endUtc.getUTCHours().toString().padStart(2, '0')}:${endUtc.getUTCMinutes().toString().padStart(2, '0')}:${endUtc.getUTCSeconds().toString().padStart(2, '0')}`;
  return { start: newStart, end: newEnd };
}

export function setWorkingHoursToUserTimezone(workingHours: { start: string, end: string }): { start: string, end: string } {
  // example of working hours in utc time: { start: '07:00:00', end: '15:00:00' }
  // example of working hours in local time: { start: '09:00:00', end: '17:00:00' }
  const start = workingHours.start.split(':');
  const end = workingHours.end.split(':');
  const startHour = parseInt(start[0]);
  const endHour = parseInt(end[0]);
  const startMinute = parseInt(start[1]);
  const endMinute = parseInt(end[1]);
  const startSecond = parseInt(start[2]);
  const endSecond = parseInt(end[2]);
  const startLocal = new Date();
  const endLocal = new Date();
  startLocal.setUTCHours(startHour, startMinute, startSecond);
  endLocal.setUTCHours(endHour, endMinute, endSecond);
  //add 0 if hour minute or second is less than 10
  const newStart = `${startLocal.getHours().toString().padStart(2, '0')}:${startLocal.getMinutes().toString().padStart(2, '0')}:${startLocal.getSeconds().toString().padStart(2, '0')}`;
  const newEnd = `${endLocal.getHours().toString().padStart(2, '0')}:${endLocal.getMinutes().toString().padStart(2, '0')}:${endLocal.getSeconds().toString().padStart(2, '0')}`;
  return { start: newStart, end: newEnd };
}


const colors = [
  "from-orange-200 to-orange-400",
  "from-red-200 to-red-400",
  "from-amber-200 to-amber-400",
  "from-yellow-200  to-yellow-400",
  "from-lime-200 to-lime-400",
  "from-green-200 to-green-400",
  "from-emerald-200 to-emerald-400",
  "from-teal-200 to-teal-400",
  "from-cyan-200 to-cyan-400",
  "from-sky-200 to-sky-400",
  "from-blue-200 to-blue-400",
  "from-indigo-200 to-indigo-400",
  "from-violet-200 to-violet-400",
  "from-purple-200 to-purple-400",
  "from-fuchsia-200 to-fuchsia-400",
  "from-pink-200 to-pink-400",
  "from-rose-200   to-rose-400",
];


export const getRandomColor = () => {
  const randomNumber = Math.floor(Math.random() * colors.length);
  const randomColor = colors[randomNumber];
  return randomColor;
}
import { cn } from '@/lib/utils'
import { calculateHeightOfEvent, calculateHeightOfTask, calculateMarginTopOfEvent, calculateMarginTopOfTask, Day, getFormatedEventStartEndTime, getFormatedTaskStartEndTime, HourlyEvent, HourlyTask, populateHoursWithEvents, populateHoursWithTasks } from '../calendar-utils';
import { useState, useEffect } from 'react'
import { Event, Schedule, SortedTask } from '@/store/schedules';
import TaskCardDialog from '@/pages/App/Schedules/components/ScheduleCard/components/TaskCard/TaskCardDialog';
import React from 'react';
import TaskCard from '@/pages/App/Schedules/components/ScheduleCard/components/TaskCard/TaskCard';

type DayCalendarProps = {
  day: Day;
  events: Event[];
  tasks: SortedTask[];
  hours: number[];
  schedule: Schedule;
};

const DayCalendar = React.forwardRef<HTMLDivElement, DayCalendarProps>((props, ref) => {
  const { day, events, tasks, hours } = props;
  const [populatedHours, setPopulatedHours] = useState<HourlyEvent[]>([]);
  const [populatedHoursWithTasks, setPopulatedHoursWithTasks] = useState<HourlyTask[]>([]);



  useEffect(() => {
    setPopulatedHours(populateHoursWithEvents(hours, events));
    setPopulatedHoursWithTasks(populateHoursWithTasks(hours, tasks));


  }, [hours, events, tasks]);

  return (
    <div key={day.date} ref={ref} className={cn("w-full min-w-[250px] text-center")}>
      <div className={cn("flex flex-col items-center w-[48px] p-1 mx-auto mb-7", day.current ? "bg-black text-white rounded-full" : "")}>
        <div className="font-bold text-sm">{day.name}</div>
        <div className="text-sm">{day.date}</div>
      </div>
      <div className="flex flex-col items-center relative ">
        {populatedHours.map((pHour) => (
          <div key={pHour.hour} className="h-[80px] w-full border-t border-gray-300"></div>
        ))}


        <div className=" absolute h-full w-full">
          <div className="relative h-full w-full">
            {populatedHours.filter(pHour => pHour.event).map((pHour, index) => (
              <div key={index} title={getFormatedEventStartEndTime(pHour.event)} className="absolute w-full bg-gradient-to-br from-gray-100 to-gray-300 rounded-md text-left text-xs p-2 text-black" style={{ height: `${calculateHeightOfEvent(pHour.event)*80}px`, marginTop: `${calculateMarginTopOfEvent(pHour.event, hours[0])*80}px` }}>{pHour.event?.title.substring(0, 30) + ((pHour.event?.title.length ?? 0) > 30 ? '...' : '')}</div>
            ))}

            {populatedHoursWithTasks.filter(pHour => pHour.task).map((pHour, index) => (
              <div key={index} title={getFormatedTaskStartEndTime(pHour.task)} className="absolute w-full" style={{ height: `${calculateHeightOfTask(pHour.task)*80}px`, marginTop: `${calculateMarginTopOfTask(pHour.task, hours[0])*80}px` }}>
                <TaskCardDialog 
                  type='edit'  
                  task={pHour.task as SortedTask} 
                  color={pHour.task?.color ?? 'bg-gradient-to-br from-orange-200 to-orange-400'} 
                  key={pHour.task?._id} 
                  schedule={props.schedule} 
                >
                  <TaskCard small={true} task={pHour.task as SortedTask} color={pHour.task?.color ?? 'bg-gradient-to-br from-orange-200 to-orange-400'} />
                </TaskCardDialog>
              </div>
            ))}
          </div>
        </div>
      </div>

    </div>
  )
})

export default DayCalendar
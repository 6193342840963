
import { create } from 'zustand';

interface SchedulePeriod {
  start: Date;
  end: Date;
}
type Subtask = {
  title: string;
  done: boolean;
}
export class Task {
  _id?: string;
  title: string;
  notes: string;
  category: string;
  priority: number;
  timeEstimated: number;
  deadline?: Date;
  reminder?: Date;
  subtasks?: Subtask[];
  progress: number;
  padding: Padding;
  color?: string;

  constructor(task: Task) {
    this._id = task._id;
    this.title = task.title;
    this.notes = task.notes;
    this.category = task.category;
    this.priority = task.priority;
    this.timeEstimated = task.timeEstimated;
    this.deadline = task.deadline ? new Date(task.deadline): undefined;
    this.reminder = task.reminder ? new Date(task.reminder) : undefined;
    this.subtasks = task.subtasks;
    this.progress = task.progress;
    this.padding = task.padding;
    this.color = task.color;
  }
}

export class Event {
  _id?: string;
  title: string;
  description: string;
  period: {
    start: Date;
    end: Date;
  };

  constructor(event: Event) {
    this._id = event._id;
    this.title = event.title;
    this.description = event.description;
    this.period = {
      start: new Date(event.period.start),
      end: new Date(event.period.end)
    }
  }
}

interface TimeSlot {
  start: Date;
  end: Date;
}
export class SortedTask extends Task {
  timeSlots: TimeSlot[];

  constructor(task: SortedTask) {
    super(task);
    this.timeSlots = [...task.timeSlots.map(timeSlot => {
      return {
        start: new Date(timeSlot.start),
        end: new Date(timeSlot.end)
      }
    })];
  }
}
interface Padding {
  before: number;
  after: number;
}

export interface Algorithm {
  _id: string;
  name: string;
  userDescription: string;
  technicalDescription: string;
  inspiration: string;
  focus: string;
  requiredTaskProperties: string[];
}

export class Schedule {
  _id?: string;
  name: string;
  period: SchedulePeriod;
  tasks?: Task[];
  events?: Event[];
  padding: Padding;
  algorithm?: Algorithm;

  constructor(schedule: Schedule) {
    this._id = schedule._id;
    this.name = schedule.name;
    this.period = {
      start: new Date(schedule.period.start),
      end: new Date(schedule.period.end)
    }
    this.tasks = schedule.tasks?.map(task => new Task(task));
    this.events = schedule.events?.map(event => new Event(event));
    this.padding = schedule.padding;
    this.algorithm = schedule.algorithm;
  }
}

interface SchedulesState {
  schedules: Schedule[];
  selectedTimelineDirection: 'future' | 'past';
  scheduleCreated: boolean;
  scheduleDeleted: boolean;
  scheduleModified: boolean;
  taskCreated: boolean;
  taskModified: boolean;
  taskDeleted: boolean;
  focusSaved: boolean;
  setSchedules: (schedules: Schedule[]) => void;
  setScheduleCreated: () => void;
  setScheduleDeleted: () => void;
  setScheduleModified: () => void;
  setTaskCreated: () => void;
  setTaskDeleted: () => void;
  setFocusSaved: () => void;
  setTaskModified: () => void;  
  setSelectTimelineDirection: (direction: 'future' | 'past') => void;

}

const useSchedulesStore = create<SchedulesState>((set) => ({
  schedules: [],
  selectedTimelineDirection: 'future',
  scheduleCreated: false,
  scheduleDeleted: false,
  scheduleModified: false,
  taskCreated: false,
  taskModified: false,
  taskDeleted: false,
  focusSaved: false,
  setSchedules: async (schedules: Schedule[]) => set({ schedules : [...schedules] }),
  setScheduleCreated: () => set((state) => ({ ...state, scheduleCreated: !(state.scheduleCreated) })),
  setScheduleDeleted: () => set((state) => ({ ...state, scheduleDeleted: !(state.scheduleDeleted) })),
  setScheduleModified: () => set((state) => ({ ...state, scheduleModified: !(state.scheduleModified) })),
  setTaskCreated: () => set((state) => ({ ...state, taskCreated: !(state.taskCreated) })),
  setTaskDeleted: () => set((state) => ({ ...state, taskDeleted: !(state.taskDeleted) })),
  setFocusSaved: () => set((state) => ({ ...state, focusSaved: !(state.focusSaved) })),
  setTaskModified: () => set((state) => ({ ...state, taskModified: !(state.taskModified) })),
  setSelectTimelineDirection: (direction: 'future' | 'past') => set({ selectedTimelineDirection: direction })


}));

export default useSchedulesStore;
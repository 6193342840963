

function Footer() {
  return (
    <div className="text-gray-600 text-xs text-center py-5">
      <p>&copy; {new Date().getFullYear()} Tamskirt. All rights reserved.</p>
    </div>
  )
}

export default Footer
import { Checkbox } from '@/components/ui/checkbox'
import { Label } from '@/components/ui/label'
import { TimePicker } from '@/components/ui/TimePicker/TimePicker'
import { useEffect, useState } from 'react';

function stringToTime(time: string) {

  const [hours, minutes, seconds] = time.split(':').map((time) => parseInt(time));
  const date = new Date();
  date.setHours(hours);
  date.setMinutes(minutes);
  date.setSeconds(seconds);
  return date;
}

function timeToString(date: Date) {
  //format: HH:MM:SS if hours is 9 should be 09
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');
  return `${hours}:${minutes}:${seconds}`;
}

function WorkingHoursPicker(props: { 
  day: string , 
  workingHours: {start: string, end: string},
  setWorkingHours: (workingHours: {start: string, end: string}) => void
} ) {

  const [end, setEnd] = useState<Date | undefined>(stringToTime(props.workingHours.end));
  const [start, setStart] = useState<Date | undefined>(stringToTime(props.workingHours.start));

  const [checked, setChecked] = useState(props.workingHours.start !== props.workingHours.end);


  useEffect(() => {

    if(end && start) {
      props.setWorkingHours({start: timeToString(start), end: timeToString(end)});
    }
    if(!checked) {
      props.setWorkingHours({start: '00:00:00', end: '00:00:00'});
    } 
  }, [start, end, checked, props]);



  function handleCheckBox() {
    setChecked(!checked);
    
  }

  return (
    <div className=" grid grid-cols-5 items-center gap-2 mb-2">
      
      <div className="">

      <Checkbox className='mr-3' checked={checked} onCheckedChange={ handleCheckBox } />
      <Label htmlFor="working-hours-monday" className="text-right ">
        {props.day}:
      </Label>
      </div>
      <TimePicker date={stringToTime(props.workingHours.start)} setDate={setStart} disabled={!checked} />
      <Label htmlFor="working-hours-monday" className="text-right ">
        to:
      </Label>
      <TimePicker date={stringToTime(props.workingHours.end)} setDate={setEnd} disabled={!checked} />
    </div>
  )
}

export default WorkingHoursPicker

import { z } from 'zod';
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import Logo from '@/assets/logo.svg'
import { TypographyP } from '@/components/typography/TypographyP';
import { Link, useNavigate } from 'react-router-dom';
import authService from '@/services/authService';
import { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { useAuthStore } from '@/store/auth';
import Footer from '../App/components/Footer/Footer';
import { PasswordInput } from '@/components/ui/password-input';

const formSchema = z.object({
  name: z.string().min(2).max(50),
  email: z.string().email(),
  password: z.string().min(6),
  passwordConfirmation: z.string().min(6),
}).superRefine(({ password, passwordConfirmation }, ctx) => {
  if (password !== passwordConfirmation) {
    ctx.addIssue({
      code: "custom",
      message: "Passwords do not match",
      path: ["passwordConfirmation"],
    });
  }
});



function RegisterForm() {

  const [ success , setSuccess ] = useState(false);
  const [ error , setError ] = useState(null);
  const {isAuthenticated, login} = useAuthStore();

  useEffect(() => {
    if(isAuthenticated) {
      navigate('/app');
    }
  });


  const navigate = useNavigate();
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: "",
      email: "",
      password: "",
      passwordConfirmation: "",
    },
  });

  async function onSubmit(values: z.infer<typeof formSchema>) {
    const { name, email, password } = values
    try {
      await authService.register(name, email, password);
      setError(null);
      setSuccess(true);

      await login(email, password);
      navigate('/login');
    }catch (error) {
      if(error instanceof AxiosError) {
        setError(error.response?.data);
        setSuccess(false);
      }
    }
    
    
  }

  return (
    <div className='min-h-screen min-w-fit flex flex-col justify-between'>
      <div className=" flex flex-col justify-evenly  py-10 ">
        <div className="flex flex-col items-center ">

          <div className="flex mb-20 ">
            <img 
              src={Logo} 
              alt="logo" 
              width={300}
            />
          </div>
          
          <div className="flex ">
            <Form {...form}>
              <form className='' onSubmit={form.handleSubmit(onSubmit)}>
                <div className="flex flex-col gap-2 items-center">
                  <div className="flex">
                    <FormField
                      control={form.control}
                      name='name'
                      render={() => (
                        <FormItem className='w-96'>
                          <FormLabel>Name</FormLabel>
                          <FormControl>
                            <Input
                              {...form.register("name")}
                              placeholder="Name"
                              type="text"
                              
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                        
                      )}
                    />
                  </div>
                  <div className="flex">
                    <FormField
                      control={form.control}
                      name='email'
                      render={() => (
                        <FormItem className='w-96'>
                          <FormLabel>Email</FormLabel>
                          <FormControl>
                            <Input
                              {...form.register("email")}
                              placeholder="Email"
                              type="email"
                              
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                        
                      )}
                    />
                  </div>
                  <div className="flex">
                    <FormField
                      control={form.control}
                      name='password'
                      render={(field) => (
                        <FormItem className='w-96'>
                          <FormLabel>Password</FormLabel>
                          <FormControl>
                            <PasswordInput
                              value={field.field.value}
                              onChange={field.field.onChange}
                              placeholder="Password"
                              
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                        
                      )}
                    />
                  </div>
                  <div className="flex">
                    <FormField
                      control={form.control}
                      name='passwordConfirmation'
                      render={(field) => (
                        <FormItem className='w-96'>
                          <FormLabel>Password Confirmation</FormLabel>
                          <FormControl>
                            <PasswordInput
                              value={field.field.value}
                              onChange={field.field.onChange}
                              placeholder="Password Confirmation"
                              
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                        
                      )}
                    />
                  </div>
                  <div className="flex mt-5">
                    <Button className='flex  rounded-none h-10 w-40' type="submit">Register</Button>
                  </div>

                  <div className="flex">
                    {error && <div className="text-red-500">{error}</div>}
                  </div>
                  <div className="flex">
                    {success && <div className="text-green-500">Registration successful. Please login.</div>}
                  </div>  
                </div>

              </form>
            </Form>
          </div>
        </div>
        
        <div className="text-center ">
          <TypographyP>
            Already have an account? <Link to='/login' className='underline'>Login</Link>
          </TypographyP>
        </div>
      </div>
      <Footer />
    </div>  
  );
}


function LoginPage() {
  return (
    <div>
      <RegisterForm />
    </div>
  )
}

export default LoginPage
import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle, SheetTrigger } from '@/components/ui/sheet'
import ViewScheduleTasksButton from './ViewScheduleTasksButton'
import useSchedulesStore, { Schedule, SortedTask } from '../../../../../../store/schedules'
import { getRandomColor, getReadableDateInUsersLocale } from '@/utils/utils'
import { ScrollArea } from '@/components/ui/scroll-area'
import TaskCardDialog from '@/pages/App/Schedules/components/ScheduleCard/components/TaskCard/TaskCardDialog'
import { Select, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue, SelectContent } from '@/components/ui/select'
import AddTaskButton from './AddTaskButton'
import { useEffect, useState } from 'react'
import schedulesService from '@/services/schedules'
import { Button } from '@/components/ui/button'
import { CheckIcon, ReloadIcon } from '@radix-ui/react-icons'
import ExportICSButton from './ExportICSButton'
import TaskCard from './TaskCard/TaskCard'





function TaskSheet(props: {schedule: Schedule}) {
  
  const [sortedTasks, setSortedTasks] = useState<SortedTask[]>([]);
  const [chosenFocus, setChosenFocus] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [newTaskCardColor, setNewTaskCardColor] = useState(getRandomColor());


  const { taskDeleted, taskCreated, setFocusSaved, taskModified } = useSchedulesStore();

  const handleSaveFocus = async () => {
    setIsLoading(true);
    const res = await schedulesService.saveFocus(props.schedule, chosenFocus);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    console.log(res);
    setFocusSaved();
  }

  
    
  useEffect(() => {
    schedulesService.getSortedTasks(props.schedule).then((tasks) => {
      setSortedTasks(tasks);
      setChosenFocus(props.schedule?.algorithm ? props.schedule?.algorithm._id : tasks[0]?.algorithm._id);

    });

    setNewTaskCardColor(getRandomColor());
    
  }, [taskCreated, taskDeleted, taskModified, props.schedule]);



  return (
    <Sheet>
      <SheetTrigger><ViewScheduleTasksButton /></SheetTrigger>
      <SheetContent className='lg:min-w-[40vw] min-w-[100vw] backdrop-blur-md bg-white/70'>
        <ScrollArea className='h-[100%]' type='scroll' scrollHideDelay={0}>
          <SheetHeader>
            <SheetTitle >{props.schedule.name}</SheetTitle>
            <SheetDescription>
              <div className='text-xs'>{`From ${getReadableDateInUsersLocale(props.schedule.period.start)}`} </div>
              <div className='text-xs'>{`To ${getReadableDateInUsersLocale(props.schedule.period.end)}`}</div>
            </SheetDescription>
          </SheetHeader>
          <div className="flex flex-col gap-3 my-3 ">
            <div className="m-5 flex justify-stretch items-center gap-3 mx-auto">
              
              <div>
                <Select onValueChange={(value: string) => setChosenFocus(value)} >
                  <SelectTrigger className="w-[100%] bg-white/30 backdrop-blur-md text-xs">
                    <SelectValue  placeholder={
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      chosenFocus &&  sortedTasks.find((sortedTask: any) => sortedTask.algorithm._id === chosenFocus) !== undefined ? 
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      (sortedTasks.find((sortedTask: any) => sortedTask.algorithm._id === chosenFocus) as any).algorithm.focus :
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      sortedTasks.length > 0 ? (sortedTasks[0] as any).algorithm.focus : "No focus available"
                    } />
                  </SelectTrigger>
                  <SelectContent className='bg-white/30 backdrop-blur-md'>
                    <SelectGroup>
                      <SelectLabel>Focus</SelectLabel>
                        { 
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        sortedTasks.map((sortedTask: any) => {
                          return <SelectItem className='text-xs' value={sortedTask.algorithm._id} key={sortedTask.algorithm.focus}>{sortedTask.algorithm.focus}</SelectItem>
                          })
                        }
                    </SelectGroup>
                  </SelectContent>
                </Select> 
                
              </div>
              

              <Button className='rounded-md' size="sm" onClick={handleSaveFocus}> {isLoading ? <ReloadIcon className="mr-2 h-4 w-4 animate-spin" /> : <CheckIcon className='mr-3'/>} Save</Button>
            </div>
            <p className='text-xs text-gray-700 m-1'>{
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  chosenFocus &&  sortedTasks.find((sortedTask: any) => sortedTask.algorithm._id === chosenFocus) !== undefined ? 
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  (sortedTasks.find((sortedTask: any) => sortedTask.algorithm._id === chosenFocus) as any).algorithm.userDescription :
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  sortedTasks.length > 0 ? (sortedTasks[0] as any).algorithm.userDescription : ""
                }</p>
            {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              chosenFocus &&  sortedTasks.find((sortedTask: any) => sortedTask.algorithm._id === chosenFocus) !== undefined ? 
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (sortedTasks.find((sortedTask: any) => sortedTask.algorithm._id === chosenFocus) as any).sortedTasks.map((task: any) => {
                return (
                  <TaskCardDialog task={task} color={task?.color ?? getRandomColor()} key={task._id} schedule={props.schedule} type='edit' >
                    <TaskCard small={false} task={task} color={task?.color ?? getRandomColor()} />
                  </TaskCardDialog>
                );
              }) :
              <div className="text-center">No tasks added.</div>
            }
            <TaskCardDialog type='add' color={newTaskCardColor} schedule={props.schedule}>
              <AddTaskButton />
            </TaskCardDialog>
            <ExportICSButton 
              schedule={props.schedule} 
              /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
              sortedTasks={(sortedTasks.find((sortedTask) => (sortedTask as any).algorithm._id === chosenFocus) as any)?.sortedTasks} 
            />
          </div>
        </ScrollArea>
      </SheetContent>
    </Sheet>
  )
}

export default TaskSheet
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import NotFoundPage from './pages/NotFound/NotFoundPage.tsx'
import './index.css'


import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import LoginPage from './pages/Login/LoginPage.tsx';
import RegisterPage from './pages/Register/RegisterPage.tsx';
import ProtectedRoute from './components/ProtectedRoute.tsx';

//init auth state
import { useAuthStore } from './store/auth';
import SchedulesPage from './pages/App/Schedules/SchedulesPage.tsx';
import Preferences from './pages/App/Preferences/PreferencesPage.tsx';
import HelpPage from './pages/App/Help/HelpPage.tsx';
import CalendarPage from './pages/App/CalendarPage/CalendarPage.tsx';
import EmailConfirmationPage from './pages/EmailConfirmation/EmailConfirmationPage.tsx';
import HomePage from './pages/Home/HomePage.tsx';


const initAuth = useAuthStore.getState().init;

//...
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://a449e7abc3a0a472de7b5f6885bad64b@o4508015236415488.ingest.de.sentry.io/4508015238512720",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.feedbackIntegration({
      // Additional SDK configuration goes in here, for example:
      colorScheme: "light",
    }),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", "tamskirt.com"],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});



initAuth();

// eslint-disable-next-line react-refresh/only-export-components
const DefaultAppRoute = SchedulesPage;


const router = createBrowserRouter([
  {
    path: "/",
    element:  <HomePage />,
    errorElement: <NotFoundPage />,
  },
  {
    path: "/app",
    element: <ProtectedRoute />,
    children: [
      {
        path: "",
        element: <DefaultAppRoute />,
      },
      {
        path: "schedules",
        element: <SchedulesPage />,
      },
      {
        path: "preferences",
        element: <Preferences />,
      },
      {
        path: "help",
        element: <HelpPage />,
      },
      {
        path: "calendar",
        element: <CalendarPage />,
      }
    ],
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/register",
    element: <RegisterPage />,
  },
  {
    path: "/email-confirmation",
    element: <EmailConfirmationPage />,
  },
]);

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <RouterProvider router={router} />
  </StrictMode>,
)

import { Button } from '@/components/ui/button';
import { Table, TableBody, TableCaption, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { getReadableDateInUsersLocale } from '@/utils/utils';
import { Event } from '@/store/schedules';
import React from 'react'

function EventsTable(props: {eventState: [Event[], React.Dispatch<React.SetStateAction<Event[]>>]}) {

  const [events, setEvents] = props.eventState;

  const handleDelete = (index: number) => {
    setEvents(events.filter((_, i) => i !== index));
  };

  return (
    <Table>
        <TableCaption>A list of your events.</TableCaption>
        <TableHeader>
          <TableRow>
            <TableHead>Title</TableHead>
            <TableHead>Description</TableHead>
            <TableHead>Start</TableHead>
            <TableHead>End</TableHead>
            <TableHead>Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {events?.map((event, index) => (
            <TableRow key={index}>
              <TableCell>{event.title}</TableCell>
              <TableCell>{event.description}</TableCell>
              <TableCell>{getReadableDateInUsersLocale(event.period.start)}</TableCell>
              <TableCell>{getReadableDateInUsersLocale(event.period.end)}</TableCell>
              <TableCell>
                <Button type='button' onClick={() => { handleDelete(index); }}>Delete</Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
  )
}

export default EventsTable
import { Button } from '@/components/ui/button';
import { DateTimePicker } from '@/components/ui/DateTimePicker';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { zodResolver } from '@hookform/resolvers/zod';
import { CheckIcon } from '@radix-ui/react-icons';
import { Event } from '@/store/schedules';
import React from 'react';
import { useForm } from 'react-hook-form';
import { IssueData, z } from 'zod';
import { Switch } from '@/components/ui/switch';

// Define form schema with validation logic
const formSchema = z.object({
  title: z.string().min(3, 'Title must be at least 3 characters').max(255, 'Title cannot exceed 255 characters'),
  description: z.string(),
  periodFrom: z.date(),
  periodTo: z.date(),
  daily: z.boolean(),
}).superRefine((data, ctx) => {
  if (data.periodFrom >= data.periodTo) {
    ctx.addIssue({
      path: ['periodFrom'],
      message: 'Start date must be before end date',
      code: 'invalid_literal',
    } as IssueData);
    ctx.addIssue({
      path: ['periodTo'],
      message: 'End date must be after start date',
      code: 'invalid_literal',
    } as IssueData);
  }
});

function AddEventForm(props: { eventsState: [Event[], React.Dispatch<React.SetStateAction<Event[]>>], events?: Event[], schedulePeriod: {start: Date, end: Date} }) {

  const [ events, setEvents ] = props.eventsState;



  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      title: "",
      description: "",
      periodFrom: new Date(),
      periodTo: new Date(),
      daily: false,
    },
  });

  const handleAddEvent = (values: z.infer<typeof formSchema>) => {
    const newEvents = [];
    console.log(values);
    if (values.daily) {
      const start = new Date(props.schedulePeriod.start);
      const end = props.schedulePeriod.end;

      for (let d = start; d.getTime() <= end.getTime(); d.setDate(d.getDate() + 1)) {
        const eventStart = new Date(new Date(d.getTime()).setHours(values.periodFrom.getHours(), values.periodFrom.getMinutes(), values.periodFrom.getSeconds()));
        const eventEnd = new Date(new Date(d.getTime()).setHours(values.periodTo.getHours(), values.periodTo.getMinutes(), values.periodTo.getSeconds()));
        newEvents.push({
          title: values.title,
          description: values.description,
          period: {
            start: eventStart,
            end: eventEnd
          }
        });
      }
    } else {
      newEvents.push({
        title: values.title,
        description: values.description,
        period: {
          start: values.periodFrom,
          end: values.periodTo
        }
      });
    }
    setEvents([...events, ...newEvents]);
    console.log('add event', [...events, ...newEvents]);
  };




  return (
    <div className="flex flex-col m-2">
      <Form {...form}>
        <form>
          <div className="flex flex-col gap-3">
            <div>
            <div className="my-3">
                Blocked Time Slots
              </div>
              <FormField control={form.control} name="title" render={({ field }) => (
                <FormItem>
                  <FormLabel>Title</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  {/* Display error message */}
                  {form.formState.errors.title && <FormMessage>{form.formState.errors.title.message}</FormMessage>}
                </FormItem>
              )} />
              <FormField control={form.control} name="description" render={({ field }) => (
                <FormItem>
                  <FormLabel>Description</FormLabel>
                  <Textarea {...field} />
                  {/* Display error message */}
                  {form.formState.errors.description && <FormMessage>{form.formState.errors.description.message}</FormMessage>}
                </FormItem>
              )} />
              <FormField control={form.control} name="periodFrom" render={({ field }) => (
                <FormItem>
                  <FormLabel>Start</FormLabel>
                  <DateTimePicker dateState={[field.value, field.onChange]} />
                  {/* Display error message */}
                  {form.formState.errors.periodFrom && <FormMessage>{form.formState.errors.periodFrom.message}</FormMessage>}
                </FormItem>
              )} />
              <FormField control={form.control} name="periodTo" render={({ field }) => (
                <FormItem>
                  <FormLabel>End</FormLabel>
                  <DateTimePicker dateState={[field.value, field.onChange]} />
                  {/* Display error message */}
                  {form.formState.errors.periodTo && <FormMessage>{form.formState.errors.periodTo.message}</FormMessage>}
                </FormItem>
              )} />

              <FormField control={form.control} name="daily" render={({ field }) => (
                <FormItem className='flex flex-row items-center justify-start space-x-5'>
                  <FormLabel>Repeat Daily</FormLabel>
                  <Switch checked={field.value} onCheckedChange={field.onChange} />
                </FormItem>
              )} />
            </div>

            {/* Submit Button */}
            <Button type="button" variant='outline' className='' onClick={form.handleSubmit((values) => {
              handleAddEvent(values);
            })}>
              <CheckIcon className='mr-2' /> Save
            </Button>
          </div>
        </form>
      </Form>
      
      
    </div>
  );
}

export default AddEventForm;
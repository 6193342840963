import axios from '@/utils/axios';
import { Schedule, SortedTask, Task } from '@/store/schedules';

const colors = [
  "from-orange-200 to-orange-400",
  "from-red-200 to-red-400",
  "from-amber-200 to-amber-400",
  "from-yellow-200  to-yellow-400",
  "from-lime-200 to-lime-400",
  "from-green-200 to-green-400",
  "from-emerald-200 to-emerald-400",
  "from-teal-200 to-teal-400",
  "from-cyan-200 to-cyan-400",
  "from-sky-200 to-sky-400",
  "from-blue-200 to-blue-400",
  "from-indigo-200 to-indigo-400",
  "from-violet-200 to-violet-400",
  "from-purple-200 to-purple-400",
  "from-rose-200 to-rose-400",
];


const getRandomColor = () => {
  const randomNumber = Math.floor(Math.random() * colors.length);
  const randomColor = colors[randomNumber];
  return randomColor;
}



class SchedulesService {
  saveFocus(schedule: Schedule, chosenFocus: string) {
    return axios.put(`/schedules/${schedule._id}`, { 
      ...schedule,
      tasks: schedule.tasks?.map(t => t._id),
      events: schedule.events?.map(e => e._id),
      algorithm: chosenFocus,
     });
  }
  deleteSchedule(schedule: Schedule) {
    return axios.delete(`/schedules/${schedule._id}`);
  }
  async getSchedules(tlDirection: 'future' | 'past', page: number, limit: number, sortOrder: 'asc' | 'desc' = 'asc') {
    return (await axios.get(`/schedules?timelineDirection=${tlDirection}&page=${page}&limit=${limit}&order=${sortOrder}`)).data;
  }

  async addSchedule(schedule: Schedule) {
    const eventIds = [];

    if(schedule.events) {
      for(const event of schedule.events) {
        const createdEvent = (await axios.post('/events', event)).data;
        eventIds.push(createdEvent._id);
      }
    }
    const newSchedule = {
      ...schedule,
      tasks: [],
      events: eventIds,
    };

    return (await axios.post('/schedules', newSchedule)).data;
  }

  async updateSchedule(schedule: Schedule, scheduleId: string) {
    const newEvents = schedule.events?.filter(e => !e._id);
    console.log('newEvents', newEvents);
    const eventIds = schedule.events?.filter(e => e._id).map(e => e._id) ?? [];
    if(newEvents) {
      for(const event of newEvents) {
        const createdEvent = (await axios.post('/events', event)).data;
        eventIds.push(createdEvent._id);
      }
    }
    const newSchedule = {
      ...schedule,
      algorithm: schedule.algorithm?._id,
      tasks: schedule.tasks?.map(t => t._id),
      events: eventIds,
    };
    console.log('newSchedule', newSchedule);
    console.log('scheduleId', scheduleId);
    return (await axios.put(`/schedules/${scheduleId}`, newSchedule)).data;
  }

  async addTask2Schedule(schedule: Schedule, task: Task) {
    const createdTask = (await axios.post(`/tasks`, {...task, color: getRandomColor()})).data;
    const newSchedule = {
      ...schedule,
      algorithm: schedule.algorithm?._id,
      tasks: [...(schedule.tasks?.map(t => t._id) as string[]), createdTask._id],
      events: schedule.events?.map(e => e._id),
    };
    return (await axios.put(`/schedules/${schedule._id}`, newSchedule)).data;
  }

  async deleteTaskFromSchedule(schedule: Schedule, task: Task) {
    const newSchedule = {
      ...schedule,
      tasks: schedule.tasks?.filter(t => t._id !== task._id).map(t => t._id),
      events: schedule.events?.map(e => e._id),
      algorithm: schedule.algorithm?._id,
    };
    return (await axios.put(`/schedules/${schedule._id}`, newSchedule)).data ;
  }

  async getSortedTasks(schedule: Schedule) {
    const tasks = (await axios.get(`/schedules/${schedule._id}/sortedTasks`)).data;
    return tasks;
  }

  async updateTask(task: Task) {
    return (await axios.put(`/tasks/${task._id}`, {
      title: task.title,
      notes: task.notes,
      category: task.category,
      priority: task.priority,
      progress: task.progress,
      timeEstimated: task.timeEstimated,
      deadline: task.deadline,  
      reminder: task.reminder,
      padding: task.padding,
      subtasks: task.subtasks,
    })).data;
  }

  async addEvent(event: Event) {
    return (await axios.post('/events', event)).data;
  }

  async exportSchedule(schedule: Schedule, sortedTasks: SortedTask[], withEvents: boolean) {
    //download file from endpoint /schedules/:id/ics
    const response = await axios.post(`/schedules/${schedule._id}/ics?withEvents=${withEvents}`, sortedTasks);
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${schedule.name}.ics`);
    document.body.appendChild(link);
    link.click();
  }

  async estimateTask(title: string, notes: string): Promise<number> {
    return (await axios.post('/tasks/estimate', { title, notes: notes ?? '' })).data.duration;
  }

  async generateSubtasks(title: string, notes: string = ''): Promise<string[]> {
    return (await axios.post('/tasks/generateSubtasks', { title, notes: notes ?? '' })).data;
  }

  async getScheduleFreeTime(schedule: Schedule): Promise<number> {
    const response = await axios.get(`/schedules/${schedule._id}/freeTime`);
    return response.data.value;
  }
  
}

export default new SchedulesService();

import { Button } from '@/components/ui/button'
import { Dialog, DialogContent, DialogTrigger, DialogHeader, DialogTitle, DialogDescription } from '@/components/ui/dialog'
import { Input } from '@/components/ui/input'
import React, { useEffect } from 'react'
import { DateTimePicker } from '@/components/ui/DateTimePicker'
import { ScrollArea } from '@/components/ui/scroll-area'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import { IssueData, z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import useSchedulesStore, { Schedule } from '../../../../../store/schedules'
import { convertSecondsToTime, convertTimeToSeconds } from '@/utils/utils'
import schedulesService from '@/services/schedules'
import { Event } from '../../../../../store/schedules'
import AddEventForm from './components/AddEventForm'
import { PlusIcon } from '@radix-ui/react-icons'
import EventsTable from './components/EventsTable'

const formSchema = z.object({
  name: z.string().min(1),
  periodFrom: z.date(),
  periodTo: z.date(),
  paddingBefore: z.string(),
  paddingAfter: z.string(),
  events: z.array(z.object({
    _id: z.string().optional(),
    title: z.string(),
    description: z.string(),
    period: z.object({
      start: z.date(),
      end: z.date()
    })
  }))
}).superRefine((data, ctx) => {
  if (data.periodFrom >= data.periodTo) {
    ctx.addIssue({
      path: ['periodFrom'], 
      message: 'Start date must be before end date',
      code: 'invalid_literal'
    } as IssueData);
    ctx.addIssue({
      path: ['periodTo'],
      message: 'End date must be after start date',
      code: 'invalid_literal'
    } as IssueData);
  }

  if (data.events.some((event: Event) => event.period.start < data.periodFrom || event.period.end > data.periodTo)) {
    ctx.addIssue({
      path: ['events'], 
      message: 'Events must be within the period',
      code: 'invalid_literal'
    } as IssueData);
  }
}
);




function ScheduleDialog(props: { children: React.ReactNode, type: 'add' | 'edit', schedule?: Schedule}) {

  const [open, setOpen] = React.useState(false);
  const [eventFormOpen, setEventFormOpen] = React.useState(false);
  const {type, schedule} = props;

  const { setScheduleCreated, setScheduleModified, scheduleModified } = useSchedulesStore();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: type === 'add' ? "" : schedule?.name,
      periodFrom: type === 'add' ? new Date() : schedule?.period.start,
      periodTo: type === 'add' ? new Date() : schedule?.period.end,
      paddingBefore: type === 'add' ? "5m" : convertSecondsToTime(schedule?.padding.before ?? 0),
      paddingAfter: type === 'add' ? "5m" : convertSecondsToTime(schedule?.padding.after ?? 0),
      events: type === 'add' ? [] : schedule?.events
    },
  });

  useEffect(() => {
    form.reset({
      name: type === 'add' ? "" : schedule?.name,
      periodFrom: type === 'add' ? new Date() : schedule?.period.start,
      periodTo: type === 'add' ? new Date() : schedule?.period.end,
      paddingBefore: type === 'add' ? "5m" : convertSecondsToTime(schedule?.padding.before ?? 0),
      paddingAfter: type === 'add' ? "5m" : convertSecondsToTime(schedule?.padding.after ?? 0),
      events: type === 'add' ? [] : schedule?.events
    });
  }, [form, schedule, scheduleModified, type]);



  const handleSave = async (values: z.infer<typeof formSchema>) => {
    console.log('values', values);
    const schedule: Schedule = {
      name: values.name,
      padding: {
        before: convertTimeToSeconds(values.paddingBefore),
        after: convertTimeToSeconds(values.paddingAfter),
      },
      period: {
        start: values.periodFrom,
        end: values.periodTo
      },
      events: values.events
    }
    
    if (type === 'add') {
      await schedulesService.addSchedule(schedule);
      setScheduleCreated();
    } else {
      schedulesService.updateSchedule({...props.schedule, ...schedule}, props.schedule?._id ?? '').then(() => {
        setScheduleModified();  
      });

      
    }
    form.reset();
    setOpen(false)
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>
        {props.children}
      </DialogTrigger>
      <DialogContent  className="min-w-[90vw] max-h-[80vh] overflow-y-auto backdrop-blur-md bg-white/70">
        <DialogHeader className='flex flex-col md:flex-row gap-2 items-center justify-between'>
          <div>
            <DialogTitle>{type === 'add' ? 'Add New' : 'Edit'} Schedule</DialogTitle>
            <DialogDescription>
          / ˈskɛdʒ ul, -ʊl, -u əl; British ˈʃɛd yul, ˈʃɛdʒ ul /, noun: a series of things to be done or of events to occur at or during a particular time or period
          </DialogDescription>
          </div>
          <Button onClick={form.handleSubmit(handleSave)}  className='w-full md:w-auto md:mr-10'>Save</Button>
        </DialogHeader>
        <ScrollArea className='h-[100%]' type='scroll' scrollHideDelay={0}>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(handleSave)}>
              <div className="grid xl:grid-cols-2">
                <div className="col-span-1 flex flex-col m-3 gap-3 ">
                  <FormField name='name' control={form.control} render={({field}) => (
                    <FormItem className='grid grid-cols-6 xl:grid-cols-12 items-center'>
                      
                      <FormLabel htmlFor="name" className="text-left grid-span-1 xl:grid-span-2">
                        Name:
                      </FormLabel>
                      <FormControl  className="grid-span-5 xl:grid-span-10 w-[250px]">
                        <Input id="name" placeholder="Your Schedule Name" className='bg-white/30 backdrop-blur-md' {...field} />
                      </FormControl>
                      <FormMessage className='col-span-12' />
                    </FormItem>
                    
                  )} />
                  <div className="mt-4">
                    Period
                  </div>
                  <div className="grid xl:grid-cols-2 ">
                    <FormField name='periodFrom' control={form.control} render={({field}) => (
                      <FormItem className=' grid xl:grid-cols-1 grid-cols-6 items-center mb-2'>
                        <FormLabel htmlFor="period-from" className="text-left col-span-1">
                          From:
                        </FormLabel>
                        <FormControl className="col-span-5">
                          <DateTimePicker 
                            className='bg-white/30 backdrop-blur-md'
                            dateState={[form.watch('periodFrom'), field.onChange]}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )} />
                    <FormField name='periodTo' control={form.control} render={({field}) => (
                      <FormItem className=' grid xl:grid-cols-1 grid-cols-6 items-center mb-2'>
                        <FormLabel htmlFor="period-to" className="text-left col-span-1">
                          To:
                        </FormLabel>
                        <FormControl className="col-span-5">
                          <DateTimePicker 
                            className='bg-white/30 backdrop-blur-md'
                            dateState={[form.watch('periodTo'), field.onChange]}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )} />
                    
                  </div>

                  <div className="mt-3">
                    Padding
                  </div>
                  <div className="grid xl:grid-cols-2 justify-items-start">
                    <FormField name='paddingBefore' control={form.control} render={({field}) => (
                      <FormItem className='grid grid-cols-6 items-center mb-2'>
                        <FormLabel htmlFor="padding-before" className="text-left grid-span-1">
                          Before:
                        </FormLabel>
                        <FormControl className="grid-span-5">
                          <Input id="padding-before" placeholder="5m" className='bg-white/30 backdrop-blur-md' {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )} />
                    <FormField name='paddingAfter' control={form.control} render={({field}) => (
                      <FormItem className='grid grid-cols-6 items-center mb-2'>
                        <FormLabel htmlFor="padding-after" className="text-left grid-span-1">
                          After:
                        </FormLabel>
                        <FormControl className="grid-span-5">
                          <Input id="padding-after" placeholder="5m" className='bg-white/30 backdrop-blur-md' {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )} />
                    
                  </div>
                </div>
                <div className="col-span-1 flex flex-col m-3 gap-3">
                  <FormField name='events' control={form.control} render={({field}) => (
                    <FormItem>
                      <FormControl className="grid-span-1">
                        <Dialog open={eventFormOpen} onOpenChange={setEventFormOpen}>
                          <DialogTrigger>
                            <Button onClick={() => setEventFormOpen(!eventFormOpen)} type='button' variant='outline' size='sm'><PlusIcon /> Add Blocked Time</Button>
                          </DialogTrigger>
                          <DialogContent className='backdrop-blur-md bg-white/70'>
                            <AddEventForm eventsState={[form.watch('events'), field.onChange]} schedulePeriod={{start: form.watch('periodFrom'), end: form.watch('periodTo')}} />
                          </DialogContent>
                        </Dialog>
                      </FormControl>
                      <FormMessage />
                      <EventsTable eventState={[form.watch('events'), field.onChange]} />
                    </FormItem>
                  )} />  
                </div>
              </div>
            </form>
          </Form>
        </ScrollArea>
      </DialogContent>
    </Dialog>
  )
}

export default ScheduleDialog


function DayDate(props: {day: string, date: string}) {

  return (
    
    <div className="flex flex-col items-center">
      <div className="text-md font-bold">{props.day}</div>
      <div className="text-md font-bold">{props.date}</div>
    </div>
    
  )
}

export default DayDate
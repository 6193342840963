import { Card, CardContent, CardFooter, CardHeader } from '@/components/ui/card'
import { cn } from '@/lib/utils'
import { SortedTask } from '@/store/schedules';
import { getReadableDateInUsersLocale } from '@/utils/utils'
import { useEffect, useState } from 'react'

function TaskCard(props: {
  task: SortedTask, 
  color: string, 
  small?: boolean
}) {
  const {task, color, small} = props;
  const [isShort, setIsShort] = useState(false);
  
  useEffect(() => {
    const timeslotInMinutes = task.timeSlots.map((timeSlot) => {
      const start = new Date(timeSlot.start);
      const end = new Date(timeSlot.end);
      return (end.getTime() - start.getTime()) / 60000;
    });
    let total = 0;
    timeslotInMinutes.forEach((minutes) => total +=minutes);
    setIsShort(total < 20);

  }, [task]);
  return (
    <Card className={cn(color, "bg-gradient-to-br", !small ? '' : 'w-full h-full')}>
          {
            !small || !isShort ? (
              <CardHeader className={cn('flex flex-row justify-between text-left', !small ? 'text-xl font-bold' : 'm-0 p-2 w-full font-light text-xs')}>
                <div>
                  {small ? task.title.substring(0, 30) + (task.title.length > 30 ? '...' : '') : task.title}
                </div>
              
              </CardHeader>
            ) : null
          }
          {
            !small && (<>
              <CardContent className='font-light text-left'>
                <div>{task.notes}</div>
                
              </CardContent>
          
              <CardFooter className='font-light text-gray-600 text-xs'>
                <div className='flex justify-between items-end w-full'>
                  <div className='text-left '>

                  {task.deadline ? (
                    <><span>Deadline: </span><div>{getReadableDateInUsersLocale(new Date(task.deadline))}</div></>
                    
                  ) : null}
                  </div>
                  <div>
                  { task.timeSlots.map((timeSlot, index) => (
                    <div key={index} className='text-xs mt-3 text-left'>
                      <div className="">
                      <span>From: </span><span>{getReadableDateInUsersLocale(new Date(timeSlot.start))}</span>
                      </div>
                      <div className="">
                      <span>To: </span><span>{getReadableDateInUsersLocale(new Date(timeSlot.end))}</span>
                      </div>
                      
                    </div>
                  ))}
                  </div>
                </div>
              </CardFooter>
            </>
          )
          }
          
        </Card>
  )
}

export default TaskCard
import { Button } from '@/components/ui/button'
import { Dialog, DialogContent, DialogHeader, DialogTrigger } from '@/components/ui/dialog'
import useSchedulesStore, { Schedule, Task } from '@/store/schedules'
import React, { useState } from 'react'
import scheduleService from '@/services/schedules';

function DeleteTaskDialog(props: {
  schedule: Schedule,
  task: Task,
  children: React.ReactNode
}) {

  const {setTaskDeleted} = useSchedulesStore();
  const {schedule, task, children: trigger} = props;

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const handleDelete = async () => {
    await scheduleService.deleteTaskFromSchedule(schedule, task);
    setTaskDeleted();
  }

  return (
    <Dialog open={deleteDialogOpen} onOpenChange={setDeleteDialogOpen}>
      <DialogTrigger asChild>
        {trigger}
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <div>Delete Task</div>
        </DialogHeader>
        
          <div>Are you sure you want to delete this task?</div>
          <div className='flex flex-row justify-end gap-3'>
            <Button onClick={handleDelete} className='bg-red-500 text-white px-3 py-1 rounded'>Delete</Button>
            <Button onClick={() => {setDeleteDialogOpen(false)}} className='bg-gray-300 text-gray-600 px-3 py-1 rounded'>Cancel</Button>
          </div>
      </DialogContent>
    </Dialog>
  )
}

export default DeleteTaskDialog
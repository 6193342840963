import { useState } from 'react'
import NavBar from '../components/NavBar/NavBar'
import { Calendar } from '@/components/calendar/calendar'
import Footer from '../components/Footer/Footer'
import { useEffect } from 'react'
import userService from '@/pages/App/services/users'
import { WorkingHours } from '../store/users'

function CalendarPage() {
  const [workingHours, setWorkingHours] = useState<WorkingHours>()
  useEffect(() => {
    userService.getUserInfo().then((user) => {
      setWorkingHours(user.preferences.workingHours)
    })
  }, [])
  return (
    <div className="pt-5 px-16 bg-white min-h-screen">
    <NavBar page='calendar' className="pb-20"  />
    <Calendar workingHours={workingHours}  />
    <Footer />
    </div>
  )
}

export default CalendarPage
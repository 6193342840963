
import DayDate from './DayDate'

function PrefixedDayDate(props: {day: string, date: string, prefix: string}) {
  return (
    <div className="flex items-center justify-evenly gap-3">
      <span className="text-xs">{props.prefix}</span> 
      <DayDate day={props.day} date={props.date} />
    </div>
  )
}

export default PrefixedDayDate
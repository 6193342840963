
import React, { useEffect } from 'react'
import WordFadeIn from '@/components/magicui/word-fade-in'
import { Input } from '@/components/ui/input'
import { FadeText } from '@/components/magicui/fade-text'
import { Button } from '@/components/ui/button'
import ResendConfirmationButton from './ResendConfirmationButton'
import {Fade} from 'react-awesome-reveal'
import { useAuthStore } from '@/store/auth'
import { useNavigate } from 'react-router-dom'


function EmailConfirmationPage() {

  const [ confirmation, setConfirmation ] = React.useState<string | undefined>(undefined);
  const { auth, confirmEmail } = useAuthStore();
  const navigate = useNavigate();

  useEffect(() => {
    if(!auth.user) {
      navigate('/login');
    }
    

  }, [auth, navigate]);

  const handleConfirmation = async () => {
    if(!confirmation) {
      return;
    }


    confirmEmail(confirmation).then(() => {
      navigate('/login');
    }).catch((error) => {
      console.error(error);
    });
    
  };

  return (
    <>
      <div className="min-h-screen flex flex-col items-center justify-evenly bg-gray-100">

        
        <div className="text-gray-800 flex flex-col items-start gap-10">
          <WordFadeIn words="🍾 Great to Have You !" className='text-5xl md:text-7xl' delay={0.2}/>
          <FadeText 
            text="Please enter the confirmation code sent to your email" 
            className='text-2xl md:text-4xl' 
            framerProps={{
              show: { transition: { delay: 1, duration: 0.8 } },
            }}
            />
        </div>
        <Fade delay={1000}>
          <div className='flex flex-col items-center'>
            <Input
              placeholder='Enter Confirmation Code'
              type='text'
              onChange={(e) => setConfirmation(e.target.value)}
              className='w-96 mb-10'
            />
            <Button
              onClick={handleConfirmation}
              className='w-60'
            >
              Confirm
            </Button>
            {/* button with counter inside it to resend confirmation email every 30s */}
            <ResendConfirmationButton className='mt-2 w-60' />

          </div>
        </Fade>

        
      </div>
        
    </>
  )
}

export default EmailConfirmationPage
import { useState, useEffect } from 'react'
import { motion, AnimatePresence } from 'framer-motion'

const words = ["Days", "Weeks", "Months"]

export function RotatingText() {
  const [currentWordIndex, setCurrentWordIndex] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length)
    }, 2000)

    return () => clearInterval(interval)
  }, [])

  return (
      <h1 className="font-bold text-black text-center">
        Plan Your{' '}
        <AnimatePresence mode="wait">
          <motion.span
            key={currentWordIndex}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
            className="inline-block text-primary"
          >
            {words[currentWordIndex]}
          </motion.span>
        </AnimatePresence>
        {' '}in
      </h1>
  )
}
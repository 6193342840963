
import PrefixedDayDate from './PrefixedDayDate'

function SchedulePeriod(props: { period: { start: Date, end: Date } }) {
  const { period } = props;
  const { start, end } = period
  const startDate = start.getDate().toString() || '';
  const endDate = end.getDate().toString() || '';
  const startDay = start.toLocaleDateString('en-US', { weekday: 'short' }) || '';
  const endDay = end.toLocaleDateString('en-US', { weekday: 'short' }) || '';
  return (
    <div className="p-3 flex flex-col gap-3">
      <PrefixedDayDate day={startDay} date={startDate} prefix="from" />
      <PrefixedDayDate day={endDay} date={endDate} prefix="to" />
    </div>
  )
}

export default SchedulePeriod
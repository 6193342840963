import { Button } from '@/components/ui/button'
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from '@/components/ui/dialog'
import { Schedule, SortedTask } from '@/store/schedules'
import schedulesService from '@/services/schedules'

function ExportICSButton(props: {sortedTasks?: SortedTask[], schedule: Schedule}) {
  const { sortedTasks, schedule } = props;

  const handleExport = async (withEvents: boolean) => {
    if(!sortedTasks) {
      return;
    }
    schedulesService.exportSchedule(schedule, sortedTasks, withEvents);
  }
  return (
    <Dialog>
      <DialogTrigger>
        <Button size='sm' className='w-full'>Export</Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Export</DialogTitle>
          <DialogDescription>Do you want to export the schedule with events?</DialogDescription>
          <DialogFooter>
            <Button onClick={() => handleExport(true)}>Yes</Button>
            <Button onClick={() => handleExport(false)}>No</Button>
          </DialogFooter>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  )
}

export default ExportICSButton
import { Card, CardContent } from "@/components/ui/card"
import SchedulePeriod from "./components/SchedulePeriod"
import ScheduleInfo from "./components/ScheduleInfo"
import { getMonthNameInUsersLocale, getReadableDateInUsersLocale } from "@/utils/utils"
import { Schedule, SortedTask, Algorithm } from "../../../../../store/schedules"
import TaskSheet from "./components/TaskSheet"              
import schedulesService from "@/services/schedules"
import ScheduleDialog from "../ScheduleDialog/ScheduleDialog"
import { Pencil2Icon } from "@radix-ui/react-icons"
import { useEffect, useState } from "react"
import ExportICSButton from "./components/ExportICSButton"
import DeleteScheduleDialog from "./components/deleteScheduleDialog"


function ScheduleCard(props: { schedule: Schedule, isFirstOfTheMonth: boolean}) {
  const { period, name, algorithm } = props.schedule
  const [isExportable, setIsExportable] = useState(false);
  const [sortedTaskList, setSortedTaskList] = useState<{algorithm: Algorithm, sortedTasks: SortedTask[]}[]>([]);

  useEffect(() => {
    setIsExportable(
      (props.schedule.events?.length ?? 0) > 0 || ((props.schedule.tasks?.length ?? 0) > 0 && props.schedule.algorithm!=undefined) 
    );

    schedulesService.getSortedTasks(props.schedule).then((sortedTaskList) => {
      setSortedTaskList(sortedTaskList);
    });

  }, [props.schedule]);

  return (
    <div className="flex flex-col gap-3 pt-10">
        {props.isFirstOfTheMonth ? (<div className="text-3xl m-3">{getMonthNameInUsersLocale(period.start)}</div>) : null}
        <Card>
          <CardContent>
            <div className="flex justify-between items-center mt-5">
              <div className="flex">
                <SchedulePeriod period={period} />
                <div className="flex flex-col justify-evenly ml-5">
                  <ScheduleDialog type="edit" schedule={props.schedule}>
                    <div className="text-lg group flex items-center text-left font-bold">
                      {name}
                      <Pencil2Icon className="w-4 h-4 ml-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                    </div>
                  </ScheduleDialog>
                  <div className=" flex text-sm flex-wrap gap-5">
                    <ScheduleInfo label="Start:" value={getReadableDateInUsersLocale(period.start)}  />
                    <ScheduleInfo label="End:" value={getReadableDateInUsersLocale(period.end)}  />
                  </div>
                  <ScheduleInfo label="Focus:" value={algorithm?.focus ? algorithm.focus : "Not defined yet"} />
                </div>
              </div>
              
              <div className="flex flex-col items- gap-2">
              <TaskSheet schedule={props.schedule} />
              { isExportable ?  <ExportICSButton schedule={props.schedule} sortedTasks={sortedTaskList.find((sorted: {algorithm: Algorithm, sortedTasks: SortedTask[]})  =>  sorted.algorithm._id === algorithm?._id)?.sortedTasks} /> : null }
              <DeleteScheduleDialog schedule={props.schedule}/>
              

              </div>
              
            </div>
          </CardContent>
        </Card>
      </div>
  )
}

export default ScheduleCard
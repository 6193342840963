import { useState, useEffect } from 'react'
import { Button } from "@/components/ui/button"
import { cn } from '@/lib/utils'
import authService from '@/services/authService'

export default function ResendConfirmationButton(props: {
  className?: string
}) {
  const [cooldown, setCooldown] = useState(0)


  useEffect(() => {
    let timer: NodeJS.Timeout
    if (cooldown > 0) {
      timer = setInterval(() => {
        setCooldown((prevCooldown) => prevCooldown - 1)
      }, 1000)
    }
    return () => clearInterval(timer)
  }, [cooldown])

  const handleResend = async () => {
    console.log("Resending confirmation...")
    setCooldown(30)
    // Here you would typically call your API to resend the confirmation
    await authService.resendEmailConfirmation();
  }

  return (
    <Button
      onClick={handleResend}
      disabled={cooldown > 0}
      variant="outline"
      className={cn("w-48", props.className)}
    >
      {cooldown > 0 ? `Resend in ${cooldown}s` : 'Resend Confirmation'}
    </Button>
  )
}

import { setWorkingHoursToUserTimezone, setWorkingHoursToUtc } from '@/utils/utils';
import axios from '../../../utils/axios';
import { UserPreferences } from '../store/users';



export class UsersService {

  public async getUserInfo() {
    const userinfo = (await axios.get('/users/info')).data;
    userinfo.createdAt = new Date(userinfo.createdAt);
    userinfo.updatedAt = new Date(userinfo.updatedAt);
    userinfo.preferences.workingHours = {
      monday: setWorkingHoursToUserTimezone(userinfo.preferences.workingHours.monday),
      tuesday: setWorkingHoursToUserTimezone(userinfo.preferences.workingHours.tuesday),
      wednesday: setWorkingHoursToUserTimezone(userinfo.preferences.workingHours.wednesday),
      thursday: setWorkingHoursToUserTimezone(userinfo.preferences.workingHours.thursday),
      friday: setWorkingHoursToUserTimezone(userinfo.preferences.workingHours.friday),
      saturday: setWorkingHoursToUserTimezone(userinfo.preferences.workingHours.saturday),
      sunday: setWorkingHoursToUserTimezone(userinfo.preferences.workingHours.sunday),
    }
    return userinfo;
  }

  public async updateUserPreferences(userPref: UserPreferences) {

    return (await axios.put('/users/preferences', {
      ...userPref,
      workingHours: {
        monday: setWorkingHoursToUtc(userPref.workingHours.monday),
        tuesday: setWorkingHoursToUtc(userPref.workingHours.tuesday),
        wednesday: setWorkingHoursToUtc(userPref.workingHours.wednesday),
        thursday: setWorkingHoursToUtc(userPref.workingHours.thursday),
        friday: setWorkingHoursToUtc(userPref.workingHours.friday),
        saturday: setWorkingHoursToUtc(userPref.workingHours.saturday),
        sunday: setWorkingHoursToUtc(userPref.workingHours.sunday),
      }
    })).data;
  }

  

}

export default new UsersService();
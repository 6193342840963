import { create } from "zustand";
import { Schedule, SortedTask } from "./schedules";
import { Day } from "@/components/calendar/calendar-utils";

// const [sortedTasks, setSortedTasks] = useState<SortedTask[]>([]);
// const [days, setDays] = useState<Day[]>([]);
// const [schedules, setSchedules] = useState<Schedule[]>([]);
// const [shownSchedule, setShownSchedule] = useState<Schedule | undefined>(undefined);
// const [shownSchedulePage, setShownSchedulePage] = useState<number>(0);
// const [shownScheduleIndex, setShownScheduleIndex] = useState<number>(0);
// const [shownSchedulePageCount, setShownSchedulePageCount] = useState<number>(0);

interface CalendarState {

  schedules: Schedule[];
  sortedTasks: SortedTask[];
  days: Day[];
  shownSchedule: Schedule | undefined;
  shownSchedulePage: number;
  shownScheduleIndex: number;
  shownSchedulePageCount: number;
  pageChanged: boolean;

  setSchedules: (schedules: Schedule[]) => void;
  setSortedTasks: (sortedTasks: SortedTask[]) => void;
  setDays: (days: Day[]) => void;
  setShownSchedule: (shownSchedule: Schedule | undefined) => void;
  setShownSchedulePage: (shownSchedulePage: number) => void;
  setShownScheduleIndex: (shownScheduleIndex: number) => void;
  setShownSchedulePageCount: (shownSchedulePageCount: number) => void;
  setPageChanged: (pageChanged: boolean) => void;


}


const useCalendarStore = create<CalendarState>((set) => ({

  schedules: [],
  sortedTasks: [],
  days: [],
  shownSchedule: undefined,
  shownSchedulePage: 0,
  shownScheduleIndex: 0,
  shownSchedulePageCount: 0,
  pageChanged: false,

  setSchedules: (schedules: Schedule[]) => set(() => ({ schedules: [...schedules] })),
  setSortedTasks: (sortedTasks: SortedTask[]) => set(() => ({ sortedTasks: [...sortedTasks] })),
  setDays: (days: Day[]) => set(() => ({ days : [...days] })),
  setShownSchedule: (shownSchedule: Schedule | undefined) => set(() => ({ shownSchedule })),
  setShownSchedulePage: (shownSchedulePage: number) => set(() => ({ shownSchedulePage })),
  setShownScheduleIndex: (shownScheduleIndex: number) => set(() => ({ shownScheduleIndex })),
  setShownSchedulePageCount: (shownSchedulePageCount: number) => set(() => ({ shownSchedulePageCount })),
  setPageChanged: (pageChanged: boolean) => set(() => ({ pageChanged })),


}));


export default useCalendarStore;
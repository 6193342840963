import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'


function SelectTimelineDirection(props: {onChange: (value: 'future' | 'past') => void, selected?: 'future' | 'past'}) {
  return (
    <div className="">
      <Select onValueChange={props.onChange} value={props.selected ?? 'future'}>
        <SelectTrigger className='text-xs'>
          <SelectValue placeholder='Future' />
        </SelectTrigger>
        <SelectContent className='text-xs'>
          <SelectItem value="future" defaultChecked>Future</SelectItem>
          <SelectItem value="past">Past</SelectItem>
        </SelectContent>
      </Select>
    </div>
  )
}

export default SelectTimelineDirection
import { WorkingHours } from "@/pages/App/store/users";
import { Schedule, SortedTask } from "@/store/schedules";
import { Event } from "@/store/schedules";

export type Day = {
  name: string;
  date: string;
  month: number;
  year: number;
  current: boolean;
  events: Event[];
}


export type HourlyEvent = {
  hour: number;
  event: Event | null;
}

export type HourlyTask = {
  hour: number;
  task: SortedTask | null;
}

export function getMaxOfWorkingHours(workingHours: WorkingHours) {
  const workingHoursArray = [
    workingHours.sunday,
    workingHours.monday,
    workingHours.tuesday,
    workingHours.wednesday,
    workingHours.thursday,
    workingHours.friday,
    workingHours.saturday,
  ].filter( period => period.start < period.end);
  const minStart = workingHoursArray.reduce((min, current) => {
      return current.start < min.start ? current : min;
  }, workingHoursArray[0]);
  const maxEnd = workingHoursArray.reduce((max, current) => {
      return current.end > max.end ? current : max;
  }, workingHoursArray[0]);
  return {
      start: minStart.start,
      end: maxEnd.end,
  }
}

export  function getListOfHoursFromPeriod(period: {start: string, end: string}) {
  const { start, end } = period;
  const startHour = parseInt(start.split(':')[0]);
  let endHour = parseInt(end.split(':')[0]);
  const endMinute = parseInt(end.split(':')[1]);
  if (endMinute > 0) {
    endHour += 1;
  }
  const hours = [];
  for (let hour = startHour; hour <= endHour; hour++) {
    hours.push(hour%24);
  }
  return hours;
}

export function getDateINReadableFormat(date: Date) {
  // Tuesday, 23rd of June, 2024
  const day = date.getDate();
  const dayOfWeek = date.toLocaleDateString('en-US', { weekday: 'long' });
  const month = date.toLocaleDateString('en-US', { month: 'long' });
  const year = date.getFullYear();
  return `${dayOfWeek}, ${month} ${day}, ${year}`;
}

export function getFormatedSchedulePeriod(schedule: Schedule | undefined) {
  if (!schedule) {
    return '';
  }
  // short month day, short year
  const start = new Date(schedule.period.start).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
  const end = new Date(schedule.period.end).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
  return `${start} - ${end}`;
}

export function getDaysFromStartToEndOfSchedule(schedule: Schedule): Day[] {
  const start = new Date(schedule.period.start);
  const end = new Date(schedule.period.end);
  const days = [];
  for (let day = start; day <= end; day.setUTCDate(day.getUTCDate() + 1)) {
    days.push({
      name: day.toLocaleDateString('en-US', { weekday: 'short' }),
      date: day.toLocaleDateString('en-US', { day: 'numeric' }),
      month: day.getMonth(),
      year: day.getFullYear(),
      current: day.toDateString() === new Date().toDateString(),
      events: [],
    });
  }
  return days;
}

export function getEventsOfTheDay(day: Day, events: Event[] | undefined) {
  if (!events) {
    return [];
  }
  const dayStart = new Date();
  dayStart.setDate(parseInt(day.date));
  dayStart.setMonth(day.month);
  dayStart.setFullYear(day.year);
  dayStart.setHours(0, 0, 0, 0);

  const dayEnd = new Date();
  dayEnd.setDate(parseInt(day.date));
  dayEnd.setMonth(day.month);
  dayEnd.setFullYear(day.year);
  dayEnd.setHours(23, 59, 59, 999);

  return events.filter((event) => {
    const eventStart = new Date(event.period.start);
    const eventEnd = new Date(event.period.end);
    return eventStart >= dayStart && eventEnd <= dayEnd;
  });
}

export function getTasksOfTheDay(day: Day, tasks: SortedTask[] | null) {
  if (!tasks) {
    return [];
  }
  const dayStart = new Date();
  dayStart.setDate(parseInt(day.date));
  dayStart.setMonth(day.month);
  dayStart.setFullYear(day.year);
  dayStart.setHours(0, 0, 0, 0);

  const dayEnd = new Date();
  dayEnd.setDate(parseInt(day.date));
  dayEnd.setMonth(day.month);
  dayEnd.setFullYear(day.year);
  dayEnd.setHours(23, 59, 59, 999);

  const tasksOfTheDay = [];

  for (const task of tasks) {
    const taskTimeSlotsOfTheDay = task.timeSlots.filter((timeslot) => {
      const timeslotStart = new Date(timeslot.start);
      const timeslotEnd = new Date(timeslot.end);
      return timeslotStart >= dayStart && timeslotEnd <= dayEnd;
    });
    if (taskTimeSlotsOfTheDay.length > 0) {
      tasksOfTheDay.push({...task, timeSlots: taskTimeSlotsOfTheDay});
    }
  }
  return tasksOfTheDay;
}

export function populateHoursWithEvents(hours: number[], events: Event[]) {
  const populatedHours: HourlyEvent[] = [];

  for (const hour of hours) {
    let eventFound = false;
    for (const event of events) {
      if (new Date(event.period.start).getHours() === hour) {
        populatedHours.push({
          hour,
          event,
        });
        eventFound = true;
      }
    }
    if (!eventFound) {
      populatedHours.push({
        hour,
        event: null,
      });
    }
  }
  return populatedHours;
}

export function populateHoursWithTasks(hours: number[], tasks: SortedTask[]) {
  const populatedHours: HourlyTask[] = [];

  const splitTasks = tasks.flatMap((task) => splitTaskIntoTimeSlots(task));

  for (const hour of hours) {
    let taskFound = false;
    for (const task of splitTasks) {
      if (new Date(task.timeSlots[0].start).getHours() === hour) {
        populatedHours.push({
          hour,
          task,
        });
        taskFound = true;
      }
    }
    if (!taskFound) {
      populatedHours.push({
        hour,
        task: null,
      });
    }
  }
  return populatedHours;
}

export function calculateMarginTopOfEvent(event: Event | null, startHour: number) {
  if (!event) {
    return 0;
  }
  const start = new Date(event.period.start);


  const marginTop = start.getHours() + start.getMinutes()/60 - startHour;
  // one number after the comma
  return Math.round(marginTop * 10) / 10;
}

export function calculateHeightOfEvent(event: Event | null) {
  if (!event) {
    return 0;
  }
  const start = new Date(event.period.start);
  const end = new Date(event.period.end);
  const height = end.getTime() - start.getTime();
  const heightPerHour = height / 1000 / 60 / 60;
  return Math.round(heightPerHour * 10) / 10;
}

export function splitTaskIntoTimeSlots(task: SortedTask | null) {
  if (!task) {
    return [];
  }
  const tasks: SortedTask[] = [];
  for (const timeSlot of task.timeSlots) {
    tasks.push({
      ...task,
      timeSlots: [timeSlot],
    });
  }
  return tasks;
}

export function calculateHeightOfTask(task: SortedTask | null) {
  if (!task) {
    return 0;
  }
  const start = new Date(task.timeSlots[0].start);
  const end = new Date(task.timeSlots[0].end);
  const height = end.getTime() - start.getTime();
  const heightPerHour = height / 1000 / 60 / 60;
  return Math.round(heightPerHour * 10) / 10;
}

export function calculateMarginTopOfTask(task: SortedTask | null, startHour: number) {
  if (!task) {
    return 0;
  }
  const start = new Date(task.timeSlots[0].start);
  const marginTop = start.getHours() + start.getMinutes()/60 - startHour;
  return Math.round(marginTop * 10) / 10;
}


export function getFormatedTime(date: Date) {
  return date.getHours().toString().padStart(2, '0') + ":" + date.getMinutes().toString().padStart(2, '0');
}

export function getFormatedStartEndTime(start: Date, end: Date) {
  return getFormatedTime(start) + " - " + getFormatedTime(end);
}

export function getFormatedEventStartEndTime(event: Event | null) {
  if (!event) {
    return '';
  }
  return getFormatedStartEndTime(new Date(event.period.start), new Date(event.period.end));
}

export function getFormatedTaskStartEndTime(task: SortedTask | null) {
  if (!task) {
    return '';
  }
  return getFormatedStartEndTime(new Date(task.timeSlots[0].start), new Date(task.timeSlots[0].end));
}




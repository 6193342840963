
function ScheduleInfo(props: { label: string, value: string}) {

  return (
    <div className="text-xs">
      <span className="font-bold text-xs">{props.label}</span> {props.value}
    </div>
  )
}

export default ScheduleInfo

import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar"
import { Link } from "react-router-dom"
import TextlessLogo from "@/assets/logo-textless.svg"
import "./NavBar.css"
import { useAuthStore } from "@/store/auth";
import { DropdownMenu, DropdownMenuTrigger } from "@radix-ui/react-dropdown-menu";
import { DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator } from "@/components/ui/dropdown-menu";
import ShinyButton from "@/components/magicui/shiny-button";

type PageName = "calendar" | "schedules" | "preferences" | "help";
function makeBoldIfActive(page: PageName, propPage: PageName | undefined) {
  return page === propPage ? "isActive" : "";
}
function NavBar(props: {page?: PageName, className?: string}) {

  const {auth, logout} = useAuthStore();
  return (
    <div className={`flex flex-row justify-between items-center min-w-screen ${props.className}`} >
    <div className="">
      <img src={TextlessLogo} alt="logo" className="h-12" />
    </div>
    <div className="text-sm flex gap-3 items-center">
        <Link to="/app/calendar" title="Coming Soon" className={`link ${makeBoldIfActive("calendar", props.page)}`}>Calendar</Link>
        <Link to="/app/schedules" className={`link ${makeBoldIfActive("schedules", props.page)}`}>Schedules</Link>
        <Link to="/app/preferences" className={`link ${makeBoldIfActive("preferences", props.page)}`}>Preferences</Link>
        <Link to="/app/help" className={`link ${makeBoldIfActive("help", props.page)}`}>Help</Link>
        <ShinyButton className="mr-5" onClick={
          () => {
            window.open("https://tamskirt.features.vote/board", "_blank");
          }
        }>Roadmap</ShinyButton>
    </div>
    <div className="">
      
      <DropdownMenu>
        <DropdownMenuTrigger>
          <Avatar className="h-9 w-9">
            <AvatarImage src="" alt="" />
            <AvatarFallback className="bg-gray-800 text-white">{auth.user?.name[0].toUpperCase()}</AvatarFallback>
          </Avatar>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="mr-5">
          <DropdownMenuLabel>{auth?.user?.name}</DropdownMenuLabel>
          <DropdownMenuSeparator />
          <DropdownMenuItem onClick={logout}>Logout</DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  </div>
  )
}

export default NavBar
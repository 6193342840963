import { Button } from "@/components/ui/button"
import { EyeOpenIcon } from "@radix-ui/react-icons"

function ViewScheduleTasksButton() {
  return (
    <Button variant="outline" size="sm" className="border-gray-800">
      <EyeOpenIcon className="mr-2 h-4 w-4" /> View Tasks
    </Button>
  )
}

export default ViewScheduleTasksButton
import { Button } from '@/components/ui/button'
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger } from '@/components/ui/dialog'
import { TrashIcon } from '@radix-ui/react-icons'
import {useState} from 'react'
import schedulesService from '@/services/schedules';
import useSchedulesStore, { Schedule } from '@/store/schedules';

function DeleteScheduleDialog(props: { schedule: Schedule}) {

  const handleDelete = async () => {
    await schedulesService.deleteSchedule(props.schedule);
    setScheduleDeleted();
  }

  const { setScheduleDeleted } = useSchedulesStore(); 

  const [open, setOpen] = useState(false);
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>
        <Button className='w-[100%]' size="sm">
          <TrashIcon /> Delete
        </Button>
      </DialogTrigger>
      <DialogContent className='bg-white/30 backdrop-blur-sm'>
        <DialogHeader>
          <DialogTitle>Are you absolutely sure?</DialogTitle>
          <DialogDescription>
            This action cannot be undone. This will permanently delete your schedule.
          </DialogDescription>
        </DialogHeader>
        <Button className='' variant='destructive' onClick={handleDelete}>Delete</Button>
      </DialogContent>
    </Dialog>
  )
}

export default DeleteScheduleDialog